import moment from "moment";
import {
  handleGetTimeByTimeZone,
  handleTimeOptions,
  localStorageHelper,
} from "utils";

export const timeValueDefaults: any = [];
for (let i = 0; i <= 23; i++) {
  if (i <= 9) {
    timeValueDefaults.push({
      id: i,
      value: `0${i}:00`,
      name: `0${i}:00`,
    });
  } else {
    timeValueDefaults.push({
      id: i,
      value: `${i}:00`,
      name: `${i}:00`,
    });
  }
}

const currentTimeZone = localStorageHelper.getItem("currentTimeZone");
const currentHour =
  moment(
    handleGetTimeByTimeZone("YYYY/MM/DD HH:mm", currentTimeZone, new Date())
  ).hour() + 2;

export const timeValueDefaults2: any = handleTimeOptions(currentHour);

export const format = "YYYY/MM/DD";
export const formatDateTime = "YYYY/MM/DD HH:mm";
