import React from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import ja from "date-fns/locale/ja";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Grid } from "antd";

import {
  WrapperDateRangePickerFirst,
  WrapperDateRangePickerSecond,
  Text,
  CalendarIconStyled,
  WrapperSelect,
  WrapperDatePicker,
  TextSpecial,
  Flex,
  TimeWrapper,
} from "./DateRangePicker.style";
import { SelectStyled } from "../../Questions.style";

registerLocale("ja", ja);
const { useBreakpoint } = Grid;
const dateFormat = "yyyy/MM/dd";

const CustomHeaderWrapper = (dateFormat: string) => {
  return function CustomHeader({
    monthDate,
    decreaseMonth,
    increaseMonth,
  }: any) {
    return (
      <>
        <button
          aria-label="Previous Month"
          className={
            "react-datepicker__navigation react-datepicker__navigation--previous"
          }
          onClick={(e: any) => {
            e.preventDefault();
            decreaseMonth();
          }}
        >
          <span
            className={
              "react-datepicker__navigation-icon react-datepicker__navigation-icon--previous"
            }
          >
            {"<"}
          </span>
        </button>
        <div className="react-datepicker__current-month">
          {format(monthDate, dateFormat)}
        </div>
        <button
          aria-label="Next Month"
          className={
            "react-datepicker__navigation react-datepicker__navigation--next"
          }
          onClick={(e: any) => {
            e.preventDefault();
            increaseMonth();
          }}
        >
          <span
            className={
              "react-datepicker__navigation-icon react-datepicker__navigation-icon--next"
            }
          >
            {">"}
          </span>
        </button>
      </>
    );
  };
};

const DateRangePicker = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  highlightDates,
  locale = "ja",
  renderDayContents,
  maxDate = true,
  minDate,
  onSelectStartDate,
  onSelectEndDate,
  type,
  startDateTime,
  endDateTime,
  onChangeStartDateTime,
  onChangeEndDateTime,
  endTimeOptions,
  startTimeOptions,
  timeValue,
  startDateOfEndDatePicker,
}: any) => {
  const { t }: any = useTranslation("translation", {
    keyPrefix: "message-delivery",
  });
  const { t: t1 }: any = useTranslation("translation", {
    keyPrefix: "vaccination",
  });

  const screens = useBreakpoint();

  if (type === "for-modal") {
    return (
      <WrapperDatePicker>
        <WrapperDateRangePickerFirst>
          <Flex>
            <div>
              <DatePicker
                dateFormat={dateFormat}
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                renderCustomHeader={CustomHeaderWrapper(
                  t("datepicker-header-format")
                )}
                highlightDates={highlightDates}
                locale={locale}
                maxDate={maxDate ? new Date() : endDate}
                renderDayContents={renderDayContents}
                placeholderText={t1("unspecified")}
                minDate={moment().toDate()}
                onSelect={onSelectStartDate}
              />
              <WrapperSelect>
                <div>{t1("start")}</div>
                <SelectStyled
                  value={startDateTime}
                  width="142px"
                  height="40px"
                  fsLabel={16}
                  background="#FFFFFF"
                  onChange={onChangeStartDateTime}
                  options={timeValue}
                />
              </WrapperSelect>
            </div>
            <CalendarIconStyled />
          </Flex>
        </WrapperDateRangePickerFirst>
        <TextSpecial>~</TextSpecial>
        <WrapperDateRangePickerSecond>
          <Flex>
            <div>
              <DatePicker
                dateFormat={dateFormat}
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                selectsEnd
                startDate={startDateOfEndDatePicker}
                endDate={endDate}
                minDate={minDate}
                renderCustomHeader={CustomHeaderWrapper(
                  t("datepicker-header-format")
                )}
                highlightDates={highlightDates}
                locale={locale}
                maxDate={maxDate ? new Date() : undefined}
                renderDayContents={renderDayContents}
                placeholderText={t1("unspecified")}
                onSelect={onSelectEndDate}
              />
              <WrapperSelect>
                <div>{t1("end-time")}</div>
                <SelectStyled
                  value={endDateTime}
                  width="142px"
                  height="40px"
                  fsLabel={16}
                  background="#FFFFFF"
                  onChange={onChangeEndDateTime}
                  options={endTimeOptions}
                />
              </WrapperSelect>
            </div>
            <CalendarIconStyled />
          </Flex>
        </WrapperDateRangePickerSecond>
      </WrapperDatePicker>
    );
  }

  return (
    <div style={{ display: "inline-flex" }}>
      <WrapperDateRangePickerFirst>
        <TimeWrapper>
          <DatePicker
            dateFormat={dateFormat}
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            renderCustomHeader={CustomHeaderWrapper(
              t("datepicker-header-format")
            )}
            highlightDates={highlightDates}
            locale={locale}
            maxDate={maxDate ? new Date() : endDate}
            renderDayContents={renderDayContents}
            placeholderText={t1("unspecified")}
            minDate={minDate ? moment().toDate() : null}
            onSelect={onSelectStartDate}
          />
          {screens.md && (
            <>
              {screens.lg && (
                <div style={{ minWidth: "fit-content", margin: "0 8px" }}>
                  {t1("start")}
                </div>
              )}
              <SelectStyled
                value={startDateTime}
                width="90px"
                fsLabel={16}
                options={startTimeOptions}
                onChange={onChangeStartDateTime}
              />
              <CalendarIconStyled />
            </>
          )}
        </TimeWrapper>
      </WrapperDateRangePickerFirst>
      <Text>~</Text>
      <WrapperDateRangePickerSecond>
        <TimeWrapper>
          <DatePicker
            dateFormat={dateFormat}
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            selectsEnd
            startDate={startDateOfEndDatePicker}
            endDate={endDate}
            minDate={minDate}
            renderCustomHeader={CustomHeaderWrapper(
              t("datepicker-header-format")
            )}
            highlightDates={highlightDates}
            locale={locale}
            maxDate={maxDate ? new Date() : undefined}
            renderDayContents={renderDayContents}
            placeholderText={t1("unspecified")}
            onSelect={onSelectEndDate}
          />
          {screens.md && (
            <>
              {screens.lg && (
                <div style={{ minWidth: "fit-content", margin: "0 8px" }}>
                  {t1("end-time")}
                </div>
              )}
              <SelectStyled
                value={endDateTime}
                width="90px"
                fsLabel={16}
                options={endTimeOptions}
                onChange={onChangeEndDateTime}
              />
              <CalendarIconStyled />
            </>
          )}
        </TimeWrapper>
      </WrapperDateRangePickerSecond>
    </div>
  );
};

export default DateRangePicker;
