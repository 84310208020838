import React, { Fragment, useState, useEffect, useRef } from "react";
import { Grid, notification } from "antd";
import { useTranslation, Trans } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import {
  DownloadIcon,
  PlusIcon,
  MinusIcon,
  TemperatureRedIcon,
  TemperatureBlueIcon,
  TemperatureYellowIcon,
  GroupIcon,
  CommentIcon,
  AddPlusCircleIcon,
  ResetIcon,
  FaceGoodIcon,
  FaceVeryGoodIcon,
  ThreeDotIcon,
  FaceNormalIcon,
  FaceVeryBadIcon,
  FaceBadIcon,
  FaceNotAttempIcon,
  ScrollableDesktop,
  ScrollableTablet,
  SendCompleteIcon,
  ConfirmFailIcon,
} from "icons";
import { Button, Select, Pagination, Checkbox1 } from "components";
import { IOption } from "components/select/Select";
import {
  generateDynamicRecords,
  localStorageHelper,
  convertDate,
  convertRawDataToOptions2,
  handleGetTimeByTimeZone,
  uuid,
} from "utils";
import { NoDataMessage } from "theme/CommonStyle";
import { useGetAttendanceOptions } from "shared-hooks";

import { BodyTemperatureData } from "models/bodyTemperature/statistics";
import {
  dataStatisticsResponse,
  selectIsLoading,
} from "redux/bodyTemperature/statistics/statisticsStates";
import { dataDestinationResponse } from "redux/bodyTemperature/destinations/destinationStates";
import { dataGroupClassResponse } from "redux/bodyTemperature/groupClass/groupClassStates";
import { createPatientProfileResponse } from "redux/bodyTemperature/createPatientProfile/patientProfileStates";
import { approveAbsenceResponse } from "redux/bodyTemperature/approveAbsence/approveAbsenceStates";
import { settingDataResponse } from "redux/setting/settingData/settingDataStates";
import { getHealthCheckSettingResponse } from "redux/setting/getHealthCheckSetting/getHealthCheckSettingStates";

import {
  GetDataStatisticsActionCreators,
  GetDataDetailFormCreators,
  GetDataBodyTemperatureSettingFormCreators,
  ApproveAbsenceCreators,
  GetHealthCheckQuestionsActionCreators,
  GetHealthCheckAnswersActionCreators,
} from "redux/rootActions";

import {
  ButtonStyled,
  ButtonDisabledStyled,
  CollapseStyled,
  WrapperSearchCondition,
  SearchConditionContainer,
  WrapperDatePicker,
  WrapperSearchConditionAction,
  WrapperSearchConditionButton,
  WrapperTableAction,
  WrapperSizeChange,
  WrapperPaginationTop,
  WrapperPaginationBottom,
  AndCondition,
  ButtonDisabledStyledWithBackground,
  TableStyled,
  AdditionalBtn,
  WapperButtonStyled,
  WrapperFace,
  WrapperThreeDot,
  ScrollableWrapper,
  WrapperItem,
  ItemContainer,
  CheckboxStyled4,
  AbsenceButton,
  AbsentModalStyled,
  WrapperBtnFooter,
  SmallMentalCheckComment,
  AttendanceText,
} from "./BodyTemperature.style";
import AdditionalModal from "./AdditionalModal";
import BodyTemperatureDetailModal from "./BodyTemperatureDetailModal";
import ConditionSettingModal from "./ConditionSettingModal";
import { AllDataForFilters } from "./constants";
import { DatePicker, Tooltip } from "./components";
import { httpStatus } from "configs/httpStatus";

const { useBreakpoint } = Grid;

export interface IModal {
  isVisible: boolean;
  handleCancel: () => void;
  fileType?: string;
  patientInfo?: any;
  selectedCharts?: any;
}

interface ICondition {
  key: string;
  value: string;
}

interface IDisplayAbsentNotice {
  isVisible: boolean;
  name: string;
  absentType: string;
  id: number;
}

interface IAbsentModal {
  handleCancel?: () => void;
  handleOk?: () => void;
  isVisible?: boolean;
}

const SearchTerms = () => {
  const { t } = useTranslation("translation", {
    keyPrefix: "body-temperature",
  });
  const { t: t1 } = useTranslation("translation", {
    keyPrefix: "class-and-activity-management",
  });
  const { t: t2 } = useTranslation("translation", {
    keyPrefix: "user-list",
  });
  const { t: t3 } = useTranslation("translation", {
    keyPrefix: "message-delivery",
  });
  const { t: t4 } = useTranslation("translation", {
    keyPrefix: "change-student-affiliation",
  });

  const screens = useBreakpoint();
  const dispatch = useDispatch();
  const currentLanguage = localStorageHelper.getItem("i18nextLng");
  const { attendanceOptions } = useGetAttendanceOptions();

  const dataStatistics = useSelector(dataStatisticsResponse);
  const dataDestination = useSelector(dataDestinationResponse);
  const dataGroupClass = useSelector(dataGroupClassResponse);
  const dataPatientProfile = useSelector(createPatientProfileResponse);
  const settingsDataResponse = useSelector(settingDataResponse);
  const isLoading = useSelector(selectIsLoading);
  const approveAbsence = useSelector(approveAbsenceResponse);
  const getHealthCheckSetting = useSelector(getHealthCheckSettingResponse);

  const {
    measurementTimeForFilter,
    filterOptions,
    expandOptions,
    familyIllnessForFilter,
    temperatureForFilter2,
    symptomsForFilter,
    marathonForFilter,
    poolForFilter,
    smallCheckForFilter,
  } = AllDataForFilters();

  const scrollAbleRef = useRef<any>(undefined);
  const firstRender: any = useRef(false);
  const [numberOfCondition, setNumberOfCondition] = useState(0);
  const [startDate, setStartDate] = useState<any>(new Date());
  const [isVisibleAddModal, setIsVisibleAddModal] = useState(false);
  const [isVisibleDetailModal, setIsVisibleDetailModal] = useState(false);
  const [isVisibleSettingModal, setIsVisibleSettingModal] = useState(false);

  const [isChangeDateSearch, setIsChangeDateSearch] = useState(false);
  const [recordOptions, setRecordOptions] = useState<IOption[]>([]);
  const [patientInfo, setPatientInfo] = useState({});
  const [displayScrollable, setDisplayScrollable] = useState<boolean>(false);

  const [filters, setFilters] = useState<any>({
    page: 1,
    per_page: 150,
    app_language_id: currentLanguage,
    aggregate_date: moment(new Date()).format("YYYY/MM/DD"),
  });
  const [filterList, setFilterList] = useState<any>(filterOptions);
  const [conditions, setCondition] = useState<ICondition[]>([]);
  const [fileType, setFileType] = useState<string>("");
  const [treeData, setTreeData] = useState<any[]>([]);
  const [displayModal, setDisplayModal] = useState<IDisplayAbsentNotice>({
    isVisible: false,
    name: "",
    absentType: "",
    id: 0,
  });

  const [destinationOptions, setDestinationOptions] = useState<any>([]);
  const [originalConditionsValue, setOriginalConditionsValue] = useState<any>({
    temperature: [],
    activities: [],
    grade: [],
    symptom: [],
    attendance: [],
    smallCheck: [],
  });
  const [statisticData, setStatisticData] = useState<any>([]);

  useEffect(() => {
    const listener = () => {
      if (
        window.innerWidth > 961 ||
        !scrollAbleRef.current ||
        firstRender.current
      )
        return;

      const { y } = scrollAbleRef.current.getBoundingClientRect();
      if (y <= 133) {
        setDisplayScrollable(true);
        setTimeout(() => {
          setDisplayScrollable(false);
          firstRender.current = true;
        }, 2000);
      }
    };
    document.addEventListener("scroll", listener);
    return () => {
      document.removeEventListener("scroll", listener);
    };
  }, [scrollAbleRef]);

  const filterData: any = {
    familyIllness: familyIllnessForFilter,
    pool_join: poolForFilter,
    marathon: marathonForFilter,
    measurementTime: measurementTimeForFilter,
    smallCheck: smallCheckForFilter,
  };

  useEffect(() => {
    if (
      getHealthCheckSetting &&
      Object.keys(getHealthCheckSetting?.health_check_setting).length
    ) {
      const clone = [];
      const expandOptionsKey = [];

      for (const ekey in expandOptions) {
        expandOptionsKey.push(ekey);
      }

      const ignoreKey = [
        "absent_type",
        "notifications",
        "can_use_absent_children_club",
        "is_attendance_stop_period",
        "is_influenza_type",
        "is_temperature_on_sick",
      ];

      for (const key in getHealthCheckSetting?.health_check_setting) {
        if (
          expandOptionsKey.includes(key) &&
          !ignoreKey.includes(key) &&
          getHealthCheckSetting?.health_check_setting[key] &&
          !getHealthCheckSetting?.operation_setting?.can_use_heart_and_health
        ) {
          clone.push(expandOptions[key]);
        }
      }
      setFilterList([...filterOptions, ...clone]);
    }
  }, [getHealthCheckSetting]);

  useEffect(() => {
    if (approveAbsence && Object.keys(approveAbsence).length) {
      if (approveAbsence.status === httpStatus.StatusCreated) {
        notification.open({
          message: moment(new Date()).format("YYYY/MM/DD HH:mm"),
          description: t("sent"),
          icon: <SendCompleteIcon />,
          placement: "bottomRight",
        });
      } else {
        notification.open({
          message: moment(new Date()).format("YYYY/MM/DD HH:mm"),
          description: t("failed"),
          icon: <ConfirmFailIcon />,
          placement: "bottomRight",
        });
      }
      dispatch(GetDataStatisticsActionCreators.getStatisticsAction(filters));
      dispatch(ApproveAbsenceCreators.handleResetAction());
    }
  }, [approveAbsence]);

  const handleScrollTableTop = () => {
    const tableBody = document.getElementsByClassName("ant-table-body");
    if (tableBody && tableBody.length) {
      return tableBody[0].scrollTo(0, 0);
    }
  };

  const handleOnChangeFilter = (index: number) => (value: string) => {
    const arrConditions = conditions.map((condition, i: number) => {
      if (i === index) {
        return {
          key: value,
          value: "",
        };
      }
      return condition;
    });
    setCondition(arrConditions);

    const convertedArrConditions = arrConditions.map((item) => {
      return item.key;
    });
    const newFilterList = filterList.map((item: any) => {
      if (convertedArrConditions.includes(item.value)) {
        return {
          ...item,
          disabled: true,
        };
      }
      return {
        ...item,
        disabled: false,
      };
    });
    setFilterList(newFilterList);
  };

  const handleOnChangeFilterData = (index: number) => (value: any) => {
    setCondition(
      conditions.map((condition, i: number) => {
        if (i === index) {
          return {
            ...condition,
            value: value,
          };
        }
        return condition;
      })
    );
  };

  const handleGetCheckedItemValue = (
    arr: any[],
    checkedArr: number[],
    itemType: string
  ) => {
    arr.forEach((item) => {
      if (item.checked) {
        checkedArr.push(item.id);
      } else {
        if (item[itemType].length) {
          handleGetCheckedItemValue(item[itemType], checkedArr, itemType);
        }
      }
    });
  };

  const handleGetSubDepartmentIds = (
    arr: any[],
    checkedArr: number[],
    itemType: string
  ) => {
    arr.forEach((item) => {
      if (item[itemType].length) {
        item[itemType].map((item: any) => {
          if (item.checked) {
            checkedArr.push(item.id);
          }
        });
      }
    });
  };

  const removeEmptyValues = (object: any) => {
    const keys = Object.keys(object);
    for (let i = 0; i < keys.length; ++i) {
      const key = keys[i];
      const value = object[key];
      if (value === null || value === undefined || value === "") {
        delete object[key];
      }
    }
  };

  const handleFilter = () => {
    let convertData: any = {};
    conditions.forEach((item) => {
      convertData = {
        ...convertData,
        [item.key]: item.value,
      };
    });

    const checkedArr: number[] = [];
    handleGetSubDepartmentIds(treeData, checkedArr, "sub_departments");

    const param: BodyTemperatureData = {
      ...filters,
      aggregate_date: convertDate(startDate),
      department_id: checkedArr,
      sub_department_id: originalConditionsValue.activities,
      temperature: originalConditionsValue.temperature,
      small_mental_check: originalConditionsValue.smallCheck,
      health_check:
        !originalConditionsValue.symptom.length ||
        originalConditionsValue.symptom.length === symptomsForFilter.length
          ? ""
          : originalConditionsValue.symptom,
      checked_at:
        convertData?.measurementTime &&
        convertData?.measurementTime.length < measurementTimeForFilter.length
          ? convertData?.measurementTime
          : "",
      attendance: originalConditionsValue.attendance,
      family_health:
        convertData?.familyIllness &&
        convertData?.familyIllness.length < familyIllnessForFilter.length
          ? convertData?.familyIllness.join(",")
          : "",
      marathon: convertData?.marathon,
      pool_join: convertData?.pool_join,
    };

    removeEmptyValues(param);

    dispatch(GetDataStatisticsActionCreators.getStatisticsAction(param));
    setFilters(param);
    handleScrollTableTop();
  };

  const handleOnChangeDefaultConditions = (name: string) => (value: any) => {
    setOriginalConditionsValue((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCheckValueOfObject = (data: any) => {
    return Object.values(data).some((item: any) => item.length > 0);
  };

  const renderTemperature = (temperature: any) => {
    return temperature.toFixed(1);
  };

  const handleCheckShowComment = (poolAswerComment: string) => {
    const txt = poolAswerComment.split(":")[1];
    if (txt && (txt.trim() === "Nothing special" || txt.trim() === "特になし"))
      return false;
    return true;
  };

  const status: any = {
    very_good: <FaceVeryGoodIcon />,
    good: <FaceGoodIcon />,
    usual: <FaceNormalIcon />,
    bad: <FaceBadIcon />,
    very_bad: <FaceVeryBadIcon />,
    not_attemp: <FaceNotAttempIcon />,
  };

  const renderIconFace = (record: any) => {
    return (
      <WrapperFace>
        {record.small_mental_check_comment
          ? status[record.small_mental_check]
          : // <Tooltip title={record.small_mental_check_comment}>
            //   {status[record.small_mental_check]}
            //   <WrapperThreeDot>
            //     <ThreeDotIcon fill="#2F8CAE" />
            //   </WrapperThreeDot>
            // </Tooltip>
            status[record.small_mental_check] || status["not_attemp"]}
      </WrapperFace>
    );
  };

  const temperatureManagementColumn = [
    {
      title: t2("full-name"),
      dataIndex: "full_name",
      key: "full_name",
      width: 230,
      fixed: window.innerWidth < 576 ? "" : "left",
      show: true,
      render: (text: string, record: any) => {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              gap: getHealthCheckSetting?.health_check_setting?.is_mental_check
                ? 0
                : 15,
            }}
          >
            <div
              style={{ height: 24, cursor: "pointer" }}
              onClick={() => {
                setIsVisibleAddModal(true);
                setPatientInfo(record);
                dispatch(
                  GetDataDetailFormCreators.getDataDetailFormAction({
                    locale: currentLanguage,
                  })
                );
                dispatch(
                  GetHealthCheckQuestionsActionCreators.getHealthCheckQuestionsAction(
                    {
                      is_all: true,
                      app_language_id: currentLanguage,
                    }
                  )
                );
              }}
            >
              <AddPlusCircleIcon />
            </div>
            {getHealthCheckSetting?.health_check_setting?.is_mental_check &&
              renderIconFace(record)}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 9,
              }}
            >
              <div style={{ display: "flex" }}>
                <span style={{ maxWidth: 135, minWidth: 135 }}>{text}</span>
                {record.sub_departments && record.sub_departments.length && (
                  <Tooltip
                    title={record.sub_departments
                      .map((sd: any) => {
                        return sd.name;
                      })
                      .join(",")}
                  >
                    <div
                      style={{
                        height: 24,
                        cursor: "pointer",
                        marginLeft: 5,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <GroupIcon />
                    </div>
                  </Tooltip>
                )}
              </div>
              {getHealthCheckSetting?.health_check_setting?.is_mental_check &&
                record.small_mental_check_comment && (
                  <SmallMentalCheckComment>
                    {record.small_mental_check_comment}
                  </SmallMentalCheckComment>
                )}
              <Button
                color="#FFFFFF"
                fontSize={16}
                fontWeight={700}
                lineHeight="23px"
                background="#2AC769"
                padding="0px 16px 1px"
                name={t("detail")}
                bdr="6px"
                border="1px solid #2AC769"
                width="112px"
                onClick={() => {
                  dispatch(
                    GetHealthCheckAnswersActionCreators.getHealthCheckAnswersAction(
                      {
                        patient_id: record?.patient_id,
                        payload: {
                          created_at_to: moment(new Date()).format(
                            "YYYY-MM-DD"
                          ),
                          app_language_id: currentLanguage,
                        },
                      }
                    )
                  );
                  setIsVisibleDetailModal(true);
                  setPatientInfo(record);
                }}
              />
            </div>
          </div>
        );
      },
    },
    {
      title: () => {
        if (currentLanguage === "ja") {
          return (
            <div>
              {t("grade-1")}
              <br /> {t("grade-2")}
            </div>
          );
        }
        return <div>{t2("grade")}</div>;
      },
      dataIndex: "department_name",
      key: "department_name",
      show: true,
      width: 95,
      fixed: window.innerWidth < 576 ? "" : "left",
      render: (text: any, record: any) => {
        return (
          <div>
            {record.parent_department_name}
            {text}
          </div>
        );
      },
    },
    {
      title: () => {
        if (currentLanguage === "ja") {
          return (
            <div>
              {t("attendance-1")}
              <br /> {t("attendance-2")}
            </div>
          );
        }
        return <div>{t2("attendance")}</div>;
      },
      dataIndex: "roll_number",
      key: "roll_number",
      width: 60,
      show: true,
      fixed: window.innerWidth < 576 ? "" : "left",
      render: (text: string) => {
        if (!text) {
          return (
            <div
              style={{
                overflow: "hidden",
                width: 65,
              }}
            >
              -
            </div>
          );
        }
        return (
          <div
            style={{
              overflow: "hidden",
              width: 65,
            }}
          >
            {text}
          </div>
        );
      },
    },
    {
      title: () => {
        if (currentLanguage === "ja") {
          return (
            <div>
              {t("input-count-1")}
              <br /> {t("input-count-2")}
            </div>
          );
        }
        return <div>{t("report-count")}</div>;
      },
      dataIndex: "answered_count",
      key: "answered_count",
      width: 86,
      show: true,
      render: (text: string, record: any) => {
        return (
          <div
            style={{
              fontWeight: record?.answered_count >= 2 ? "bold" : "normal",
            }}
          >
            {text}
          </div>
        );
      },
    },
    {
      title: t3("attendance-status"),
      dataIndex: "attendance",
      key: "attendance",
      show:
        !getHealthCheckSetting?.operation_setting?.can_use_heart_and_health &&
        getHealthCheckSetting?.health_check_setting?.is_attendance,
      width: 142,
      render: (text: string, record: any) => {
        if (text === "-" || !text) {
          return <span>-</span>;
        }
        let color;
        switch (text) {
          case t("sick_leave"):
          case t("absent-corona-positive"):
          case t("absent-close-contact"):
          case t("absent-family-close-contact"):
          case t("absent-influenza"):
          case t("attendance"):
          case t("attendance-have-contact"):
          case t("absent"):
          case t("absent-sick-leave"):
            color = "#FB4E4E";
            break;
          case t("late"):
            color = "#F49506";
            break;
          case t("other_leave"):
          case t("absent-other"):
          case t("absent-other-1"):
            color = "#EBC300";
            break;
          case t("leave_early"):
            color = "#0A88CE";
            break;
          case t("absent-children-club"):
            color = "#2A98C7";
            break;
          case t("absent-bereavement"):
            color = "#9EC72A";
            break;
          default:
            color = "rgba(0, 0, 0, 0.85)";
        }

        return (
          <>
            <div style={{ display: "flex", alignItems: "center" }}>
              {record.is_notificated ? (
                <span
                  style={{ marginRight: 5, color: "#B7B7B7", minWidth: 135 }}
                >
                  {text}
                </span>
              ) : text === t("present") ? (
                <span style={{ marginRight: 5, color: color, minWidth: 135 }}>
                  {text}
                </span>
              ) : getHealthCheckSetting?.health_check_setting?.notifications
                  .is_attendance_confirmation ? (
                <AbsenceButton
                  background={color}
                  onClick={() =>
                    setDisplayModal({
                      id: record.id,
                      isVisible: true,
                      name: record.full_name,
                      absentType: text,
                    })
                  }
                >
                  {text}
                </AbsenceButton>
              ) : (
                <span style={{ marginRight: 5, color: color, minWidth: 135 }}>
                  {text}
                </span>
              )}
              {record.attendance_comment && (
                <Tooltip title={record.attendance_comment}>
                  <div
                    style={{
                      height: 24,
                      cursor: "pointer",
                      marginLeft: 5,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <CommentIcon fill="#2F8CAE" />
                  </div>
                </Tooltip>
              )}
            </div>
            {record.absent_reason && (
              <AttendanceText style={{ marginTop: 2 }}>
                {record.absent_reason}
              </AttendanceText>
            )}
            {record.absent_reason_comment && (
              <AttendanceText>{record.absent_reason_comment}</AttendanceText>
            )}
            {record.influenza_type && (
              <AttendanceText>{record.influenza_type}</AttendanceText>
            )}
            {record.attendance_stop_period && (
              <>
                <AttendanceText>
                  {t("attendance-suspension-date")}
                </AttendanceText>
                <AttendanceText>
                  {moment(record.attendance_stop_period).format(
                    "YYYY年MM月DD日"
                  )}
                </AttendanceText>
              </>
            )}
          </>
        );
      },
    },
    {
      title: t("physical-condition"),
      dataIndex: "health_check",
      key: "health_check",
      width: 135,
      show: getHealthCheckSetting?.health_check_setting?.is_health_check,
      render: (text: string, record: any) => {
        if (text === "-" || !text) {
          return <span>-</span>;
        }
        return (
          <div>
            {text === t("have-malfunction") ? (
              <span
                style={{
                  fontWeight: "bold",
                  fontSize: 16,
                }}
              >
                {text}
              </span>
            ) : (
              text
            )}
            {record.disorder_list && record.disorder_list.length > 0 && (
              <div
                style={{
                  fontSize: 14,
                  lineHeight: "18px",
                  fontWeight: 400,
                  marginTop: 2,
                }}
              >
                {record.disorder_list.map((item: any, idx: number) => {
                  return <div key={idx}>・{item}</div>;
                })}
              </div>
            )}
            {record.disorder_comment && (
              <div style={{ fontSize: 14, lineHeight: "18px" }}>
                <br />
                {record.disorder_comment}
              </div>
            )}
          </div>
        );
      },
    },
    {
      title: t3("body-temperature"),
      dataIndex: "temperature",
      key: "temperature",
      width: 135,
      show: true,
      render: (text: any, record: any) => {
        if (!record.temperature) {
          return <div>-</div>;
        }
        const temperature = Number(record.temperature);
        if (temperature >= 37.5) {
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <TemperatureRedIcon />
              <div>
                <span
                  style={{ marginLeft: 5, color: "#FB4E4E", fontWeight: 700 }}
                >
                  {renderTemperature(temperature)}°C
                </span>
              </div>
            </div>
          );
        }
        if (temperature >= 37 && temperature < 37.5) {
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <TemperatureYellowIcon />
              <div>
                <span
                  style={{ marginLeft: 5, color: "#E89806", fontWeight: 700 }}
                >
                  {renderTemperature(temperature)}°C
                </span>
              </div>
            </div>
          );
        }
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <TemperatureBlueIcon />
            <div>
              <span style={{ marginLeft: 5 }}>
                {renderTemperature(temperature)}°C
              </span>
            </div>
          </div>
        );
      },
    },
    {
      title: () => {
        if (currentLanguage === "ja") {
          return (
            <div>
              {t("measure-time-1")}
              <br /> {t("measure-time-2")}
            </div>
          );
        }
        return <div>{t("measure-time")}</div>;
      },
      dataIndex: "created_at",
      key: "created_at",
      width: 86,
      show: true,
      render: (text: any) => {
        if (!text) {
          return <div>-</div>;
        }
        const currentTimeZone = localStorageHelper.getItem("currentTimeZone");
        return (
          <div>{handleGetTimeByTimeZone("HH:mm", currentTimeZone, text)}</div>
        );
      },
    },
    {
      title: t("breakfast"),
      dataIndex: "breakfast",
      key: "breakfast",
      width: 110,
      show: getHealthCheckSetting?.health_check_setting?.is_breakfast,
      render: (text: string, record: any) => {
        if (text === "-" || !text) {
          return <div>-</div>;
        }
        return (
          <div>
            {text}
            {getHealthCheckSetting?.health_check_setting
              ?.can_use_breakfast_comment &&
              record.breakfast_comment && (
                <div style={{ fontSize: 14, lineHeight: "18px" }}>
                  {record.breakfast_comment}
                </div>
              )}
          </div>
        );
      },
    },
    {
      title: t3("pool"),
      dataIndex: "pool_join",
      key: "pool_join",
      width: 130,
      show:
        !getHealthCheckSetting?.operation_setting?.can_use_heart_and_health &&
        getHealthCheckSetting?.health_check_setting?.is_pool,
      render: (text: string, record: any) => {
        if (text === "-" || !text) {
          return <span style={{ marginRight: 5 }}>-</span>;
        }
        return (
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              gap: 2,
              flexDirection: "column",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              {text === t("non-participation") ? (
                <span
                  style={{
                    fontWeight: "bold",
                    fontSize: 16,
                    marginRight: 5,
                  }}
                >
                  {text}
                </span>
              ) : (
                <span
                  style={{
                    marginRight: 5,
                  }}
                >
                  {text}
                </span>
              )}
              {record.pool_join_comment &&
                handleCheckShowComment(record.pool_join_comment) && (
                  <Tooltip title={record.pool_join_comment}>
                    <CommentIcon fill="#2F8CAE" />
                  </Tooltip>
                )}
            </div>
            {record.pool_disorder && record.pool_disorder.length > 0 && (
              <div
                style={{
                  fontSize: 14,
                  lineHeight: "18px",
                  fontWeight: 400,
                }}
              >
                {record.pool_disorder.map((item: any, idx: number) => {
                  return <div key={idx}>・{item}</div>;
                })}
              </div>
            )}
            {record.pool_disorder_comment && (
              <div style={{ fontSize: 14, lineHeight: "18px" }}>
                <br />
                {record.pool_disorder_comment}
              </div>
            )}
          </div>
        );
      },
    },
    {
      title: t("marathon"),
      dataIndex: "marathon_question",
      key: "marathon_question",
      width: 100,
      show:
        !getHealthCheckSetting?.operation_setting?.can_use_heart_and_health &&
        getHealthCheckSetting?.health_check_setting?.is_marathon,
      render: (text: any, record: any) => {
        if (text === "-" || !text) {
          return <span style={{ marginRight: 5 }}>{text}</span>;
        }
        return (
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              gap: 2,
              flexDirection: "column",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              {text === t("non-participation") ? (
                <span
                  style={{
                    fontWeight: "bold",
                    fontSize: 16,
                    marginRight: 5,
                  }}
                >
                  {text}
                </span>
              ) : (
                <span
                  style={{
                    marginRight: 5,
                  }}
                >
                  {text}
                </span>
              )}
              {record.marathon_question_comment &&
                handleCheckShowComment(record.marathon_question_comment) && (
                  <Tooltip title={record.marathon_question_comment}>
                    <CommentIcon fill="#2F8CAE" />
                  </Tooltip>
                )}
            </div>
          </div>
        );
      },
    },
    {
      title: () => {
        return (
          <div>
            {t3("family-of")}
            <br /> {t3("illness")}
          </div>
        );
      },
      dataIndex: "family_health_check",
      key: "family_health_check",
      width: 100,
      show:
        !getHealthCheckSetting?.operation_setting?.can_use_heart_and_health &&
        getHealthCheckSetting?.health_check_setting?.is_family_health_check,
      render: (text: string, record: any) => {
        if (text === "-" || !text) {
          return <span style={{ marginRight: 5 }}>{text}</span>;
        }
        if (text === t("presence") || text.search("presence") !== -1) {
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <span style={{ marginRight: 5, color: "#FB4E4E" }}>{text}</span>
              {record.family_health_check_comment && (
                <Tooltip title={record.family_health_check_comment}>
                  <CommentIcon fill="#2F8CAE" />
                </Tooltip>
              )}
            </div>
          );
        }
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <span style={{ marginRight: 5 }}>{text}</span>
            {record.family_health_check_comment && (
              <Tooltip title={record.family_health_check_comment}>
                <CommentIcon fill="#2F8CAE" />
              </Tooltip>
            )}
          </div>
        );
      },
    },
    {
      title: () => {
        return (
          <div>
            {t("patient-corona")}
            <br /> {t("contact")}
          </div>
        );
      },
      dataIndex: "contact_coronavirus",
      key: "contact_coronavirus",
      width: 110,
      show:
        !getHealthCheckSetting?.operation_setting?.can_use_heart_and_health &&
        getHealthCheckSetting?.health_check_setting?.is_action_check,
      render: (text: string, record: any) => {
        if (text === "-" || !text) {
          return <div>-</div>;
        }
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <span style={{ marginRight: 5 }}>{text || t("no")}</span>
            {record.contact_coronavirus_comment && (
              <Tooltip title={record.contact_coronavirus_comment}>
                <CommentIcon fill="#2F8CAE" />
              </Tooltip>
            )}
          </div>
        );
      },
    },
    {
      title: t("travel"),
      dataIndex: "have_traveled",
      key: "have_traveled",
      width: 110,
      show:
        !getHealthCheckSetting?.operation_setting?.can_use_heart_and_health &&
        getHealthCheckSetting?.health_check_setting?.is_action_check,
      render: (text: string, record: any) => {
        if (text === "-" || !text) {
          return <span style={{ marginRight: 5 }}>-</span>;
        }
        if (text === t("no")) {
          return <div>{text}</div>;
        }
        return (
          <div>
            <span
              style={{ marginBottom: 5, color: "#FB4E4E", fontWeight: 700 }}
            >
              {t2("yes")}
            </span>
            <div>
              {record.travel_abroad_comment && (
                <Tooltip title={record.travel_abroad_comment}>
                  <CommentIcon
                    fill="#2F8CAE"
                    style={{ position: "relative", top: 2 }}
                  />
                </Tooltip>
              )}
              <div
                style={{
                  fontSize: 14,
                  lineHeight: "18px",
                  fontWeight: 400,
                }}
              >
                {record?.travel_abroad?.length &&
                  record?.travel_abroad.map((item: any, idx: number) => {
                    return <div key={idx}>・{item}</div>;
                  })}
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: () => {
        return (
          <div>
            {t("event")}
            <br /> {t("participation-event")}
          </div>
        );
      },
      dataIndex: "participate_events",
      key: "participate_events",
      width: 110,
      show:
        !getHealthCheckSetting?.operation_setting?.can_use_heart_and_health &&
        getHealthCheckSetting?.health_check_setting?.is_action_check,
      render: (text: string, record: any) => {
        if (text === "-" || !text) {
          return <span style={{ marginRight: 5 }}>-</span>;
        }
        if (text === t("no")) {
          return <div>{text}</div>;
        }
        return (
          <div>
            <span
              style={{ marginBottom: 5, color: "#FB4E4E", fontWeight: 700 }}
            >
              {t2("yes")}
            </span>
            <div>
              {record.about_participated_event_comment && (
                <Tooltip title={record.about_participated_event_comment}>
                  <CommentIcon
                    fill="#2F8CAE"
                    style={{ position: "relative", top: 2 }}
                  />
                </Tooltip>
              )}
              <div
                style={{
                  fontSize: 14,
                  lineHeight: "18px",
                  fontWeight: 400,
                }}
              >
                {record?.about_participated_event?.length &&
                  record?.about_participated_event.map(
                    (item: any, idx: number) => {
                      return <div key={idx}>・{item}</div>;
                    }
                  )}
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: () => {
        return (
          <div>
            {t("dinner")}
            <br />
            {t("participation-meeting")}
          </div>
        );
      },
      dataIndex: "meal_in_multiple_person",
      key: "meal_in_multiple_person",
      width: 110,
      show:
        !getHealthCheckSetting?.operation_setting?.can_use_heart_and_health &&
        getHealthCheckSetting?.health_check_setting?.is_action_check,
      render: (text: string, record: any) => {
        if (text === "-" || !text) {
          return <span style={{ marginRight: 5 }}>-</span>;
        }
        if (text === t("no")) {
          return <div>{text}</div>;
        }
        return (
          <div>
            <span
              style={{ marginBottom: 5, color: "#FB4E4E", fontWeight: 700 }}
            >
              {t2("yes")}
            </span>
            <div>
              {record.about_meal_in_multiple_person_comment && (
                <Tooltip title={record.about_meal_in_multiple_person_comment}>
                  <CommentIcon
                    fill="#2F8CAE"
                    style={{ position: "relative", top: 2 }}
                  />
                </Tooltip>
              )}
              <div
                style={{
                  fontSize: 14,
                  lineHeight: "18px",
                  fontWeight: 400,
                }}
              >
                {record?.about_meal_in_multiple_person?.length &&
                  record?.about_meal_in_multiple_person.map(
                    (item: any, idx: number) => {
                      return <div key={idx}>・{item}</div>;
                    }
                  )}
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: t("part-time-job"),
      dataIndex: "have_part_time_job",
      key: "have_part_time_job",
      width: 110,
      show:
        !getHealthCheckSetting?.operation_setting?.can_use_heart_and_health &&
        getHealthCheckSetting?.health_check_setting?.is_action_check,
      render: (text: string, record: any) => {
        if (text === "-" || !text) {
          return <span style={{ marginRight: 5 }}>-</span>;
        }
        if (text === t("no")) {
          return <div>{text}</div>;
        }
        return (
          <div>
            <span
              style={{ marginBottom: 5, color: "#FB4E4E", fontWeight: 700 }}
            >
              {t2("yes")}
            </span>
            <div>
              {record.about_part_time_job_comment && (
                <Tooltip title={record.about_part_time_job_comment}>
                  <CommentIcon
                    fill="#2F8CAE"
                    style={{ position: "relative", top: 2 }}
                  />
                </Tooltip>
              )}
              <div
                style={{
                  fontSize: 14,
                  lineHeight: "18px",
                  fontWeight: 400,
                }}
              >
                {record?.about_part_time_job?.length &&
                  record?.about_part_time_job.map((item: any, idx: number) => {
                    return <div key={idx}>・{item}</div>;
                  })}
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: () => {
        return (
          <div>
            {t("other")}
            <br />
            {t("dense-action")}
          </div>
        );
      },
      dataIndex: "other_action",
      key: "other_action",
      width: 110,
      show:
        !getHealthCheckSetting?.operation_setting?.can_use_heart_and_health &&
        getHealthCheckSetting?.health_check_setting?.is_action_check,
      render: (text: string, record: any) => {
        if (text === "-" || !text) {
          return <div>-</div>;
        }
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <span style={{ marginRight: 5 }}>{text || t("no")}</span>
            {record.other_action_comment && (
              <Tooltip title={record.other_action_comment}>
                <CommentIcon fill="#2F8CAE" />
              </Tooltip>
            )}
          </div>
        );
      },
    },
  ].filter((column: any) => column.show);

  useEffect(() => {
    if (dataStatistics && dataStatistics?.answers?.length) {
      let recordOptionsClone: IOption[] = [];
      const statisticItems: any[] = [];
      dataStatistics.answers.map((item: any) =>
        statisticItems.push({ ...item, key: item.id ? item.id : uuid() })
      );
      recordOptionsClone = generateDynamicRecords(
        dataStatistics?.total_number,
        t2,
        [150, 300, 500]
      );
      setRecordOptions([...recordOptionsClone]);
      setStatisticData(statisticItems);
    }
    if (dataStatistics && !dataStatistics?.answers?.length) {
      setStatisticData([]);
    }
  }, [dataStatistics]);

  useEffect(() => {
    if (dataDestination && dataDestination?.result.length) {
      const dataDesinationClone = convertRawDataToOptions2(
        dataDestination?.result
      );
      setDestinationOptions(dataDesinationClone);
    }
  }, [dataDestination]);

  useEffect(() => {
    if (dataGroupClass && dataGroupClass?.result.length) {
      const clone = [...dataGroupClass?.result];
      setTreeData(clone);
    }
  }, [dataGroupClass]);

  useEffect(() => {
    if (dataPatientProfile?.message === "success") {
      setIsVisibleAddModal(false);
      dispatch(GetDataStatisticsActionCreators.getStatisticsAction(filters));
    }
  }, [dataPatientProfile]);

  const handleAddCondition = () => {
    const newCondition = { key: "", value: "" };
    setCondition([...conditions, newCondition]);
    setNumberOfCondition(numberOfCondition + 1);
  };

  const handleRemoveCondition = () => {
    const lastCondition = conditions.splice(-1);
    const newFilterList = filterList.map((item: any) => {
      if (item.value === lastCondition[0].key) {
        return {
          ...item,
          disabled: false,
        };
      }
      return item;
    });
    setFilterList(newFilterList);

    const convertData: any = conditions.reduce(
      (obj, item) => ({
        ...obj,
        [item.key]: item.value,
      }),
      {}
    );

    const param: BodyTemperatureData = {
      ...filters,
      aggregate_date: convertDate(startDate),
      app_language_id: currentLanguage,
      department_id: originalConditionsValue.grade,
      sub_department_id: originalConditionsValue.activities,
      temperature: originalConditionsValue.temperature,
      health_check: originalConditionsValue.symptom,
      small_mental_check:
        convertData?.smallCheck && convertData?.smallCheck.length > 0
          ? convertData.smallCheck
          : "",
      checked_at:
        convertData?.measurementTime &&
        convertData?.measurementTime.length < measurementTimeForFilter.length
          ? convertData?.measurementTime
          : "",
      attendance: originalConditionsValue.attendance,
      family_health:
        convertData?.familyIllness &&
        convertData?.familyIllness.length < familyIllnessForFilter.length
          ? convertData?.familyIllness.join(",")
          : "",
      marathon: convertData?.marathon,
      pool_join: convertData?.pool_join,
    };

    removeEmptyValues(param);

    setFilters(param);
    setCondition([...conditions]);
  };

  const handleChangePage = (page: number) => {
    const param = {
      ...filters,
      page,
    };
    dispatch(GetDataStatisticsActionCreators.getStatisticsAction(param));
    setFilters(param);
    if (
      scrollAbleRef.current &&
      window.scrollY >= scrollAbleRef.current.offsetTop
    ) {
      window.scrollTo(0, scrollAbleRef.current.offsetTop);
    }
    handleScrollTableTop();
  };

  const handleSizeChange = (sizeChange: number) => {
    const param = {
      ...filters,
      page: 1,
      per_page: sizeChange,
    };
    dispatch(GetDataStatisticsActionCreators.getStatisticsAction(param));
    setFilters(param);
  };

  const handleCheckTreeData = () => {
    const checkedArr: number[] = [];
    handleGetCheckedItemValue(treeData, checkedArr, "sub_departments");
    return checkedArr.length;
  };

  const handleBtnStatus = () => {
    return !(
      conditions.length ||
      isChangeDateSearch ||
      handleCheckValueOfObject(originalConditionsValue) ||
      handleCheckTreeData()
    );
  };

  const handleCheckedItem = (item: any, itemType: string, e: any) => {
    item.checked = e;
    if (item[itemType].length) {
      item[itemType].map((newItem: any) => {
        handleCheckedItem(newItem, itemType, item.checked);
      });
    }
  };

  const onChangeCheckbox = (item: any, itemType: string) => (e: any) => {
    handleCheckedItem(item, itemType, e.target.checked);
    if (itemType === "sub_departments") {
      setTreeData([...treeData]);
    } else {
      // setTreeData2([...treeData2]);
    }
  };

  const CompItem = ({
    item,
    itemType,
    children,
  }: {
    item: any;
    itemType: string;
    children: any;
  }) => {
    const { name, checked } = item;

    const handleCheckParent = (item: any, checked: boolean) => {
      item.checked = checked;
      if (itemType === "sub_departments") {
        setTreeData([...treeData]);
      } else {
        // setTreeData2([...treeData2]);
      }
    };

    useEffect(() => {
      if (item[itemType].length > 0) {
        if (
          !item[itemType].some(
            (d: any) => d.checked === false || d.checked === undefined
          )
        ) {
          if (!item.checked) {
            handleCheckParent(item, true);
          }
        } else {
          if (item.checked) {
            handleCheckParent(item, false);
          }
        }
      }
    }, [item[itemType]]);

    return (
      <>
        <ItemContainer>
          <CheckboxStyled4
            checked={checked}
            onChange={onChangeCheckbox(item, itemType)}
          >
            <Tooltip placement="topLeft" title={name}>
              <div style={{ marginLeft: 10 }}>{name}</div>
            </Tooltip>
          </CheckboxStyled4>
        </ItemContainer>
        {children}
      </>
    );
  };

  const Item = ({ item, itemType }: { item: any; itemType: string }) => {
    return (
      <CompItem item={item} itemType={itemType}>
        {item[itemType].length > 0 &&
          item[itemType].map((i: any) => (
            <div style={{ marginLeft: 32 }} key={i.id}>
              <CompItem item={i} itemType={itemType}>
                {i[itemType].map((a: any) => (
                  <div style={{ marginLeft: 32 }} key={a.id}>
                    <Item item={a} itemType={itemType} />
                  </div>
                ))}
              </CompItem>
            </div>
          ))}
      </CompItem>
    );
  };

  const GroupButton = () => (
    <WapperButtonStyled style={{ display: "flex" }}>
      <ButtonStyled
        color="#FFFFFF"
        fontSize={16}
        fontWeight={700}
        lineHeight="16px"
        background="#2AC769"
        margin="0px 15px 0px 0px"
        name={t("download-csv")}
        bdr="6px"
        border="1px solid #2AC769"
        onClick={() => {
          setIsVisibleSettingModal(true);
          setFileType("csv");
          dispatch(
            GetDataBodyTemperatureSettingFormCreators.getSettingFormData({
              locale: currentLanguage,
            })
          );
        }}
        icon={
          <DownloadIcon
            width="14px"
            height="14px"
            fill="#FFFFFF"
            style={{
              position: "absolute",
              left: screens.xs ? 30 : 12,
              top: 10,
            }}
          />
        }
      />
      <ButtonStyled
        color="#FFFFFF"
        fontSize={16}
        fontWeight={700}
        lineHeight="16px"
        background="#2AC769"
        name={t("download-pdf")}
        bdr="6px"
        border="1px solid #2AC769"
        onClick={() => {
          setIsVisibleSettingModal(true);
          setFileType("pdf");
          dispatch(
            GetDataBodyTemperatureSettingFormCreators.getSettingFormData({
              locale: currentLanguage,
            })
          );
        }}
        icon={
          <DownloadIcon
            width="14px"
            height="14px"
            fill="#FFFFFF"
            style={{
              position: "absolute",
              left: screens.xs ? 30 : 12,
              top: 10,
            }}
          />
        }
      />
    </WapperButtonStyled>
  );

  const ConfirmAbsentModal: React.FC<IAbsentModal> = ({
    handleCancel,
    handleOk,
    isVisible,
    children,
  }) => {
    const { t }: any = useTranslation("translation", {
      keyPrefix: "user-list",
    });

    return (
      <AbsentModalStyled
        type="confirm"
        footer={
          <WrapperBtnFooter>
            <Button
              onClick={handleCancel}
              background="#E0E0E0"
              color="#fff"
              name={t("cancel")}
              border="none"
              bdr="6px"
              fontSize={16}
              fontWeight={700}
            />

            <Button
              onClick={handleOk}
              background="#FB4E4E"
              color="#fff"
              name="OK"
              border="none"
              bdr="6px"
              fontSize={16}
              fontWeight={700}
            />
          </WrapperBtnFooter>
        }
        visible={isVisible}
      >
        {children}
      </AbsentModalStyled>
    );
  };

  const ActionSection = () => {
    return (
      <div
        style={{ display: "flex", justifyContent: "space-between", padding: 8 }}
      >
        <AdditionalBtn
          color="#1AB759"
          fontSize={14}
          fontWeight={500}
          lineHeight="16px"
          background="#FFFFFF"
          padding="3px 5px 4px"
          name={t("plus")}
          bdr="4px"
          border="1px solid #1AB759"
          icon={<PlusIcon fill="#1AB759" />}
          onClick={() => {
            dispatch(
              GetDataDetailFormCreators.getDataDetailFormAction({
                locale: currentLanguage,
              })
            );
            dispatch(
              GetHealthCheckQuestionsActionCreators.getHealthCheckQuestionsAction(
                {
                  is_all: true,
                  app_language_id: currentLanguage,
                }
              )
            );
            setIsVisibleAddModal(true);
            setPatientInfo({});
          }}
        />
        <div style={{ fontSize: 18, lineHeight: "24px" }}>
          {t3("search-applicable")}
          {dataStatistics?.total_number}
          {t3("people")}
        </div>
      </div>
    );
  };
  const getValueForSelect = (condition: any) => {
    if (
      condition.key &&
      condition.value.length === filterData[condition.key].length
    ) {
      return t3("all");
    } else if (condition.key && condition.value.length > 1) {
      return t("multiple-selection");
    } else {
      return condition.value || "";
    }
  };

  const getValueForSelect2 = (value: any, options: any) => {
    if (value.length === options.length) return t3("all");
    if (value.length > 1) return t("multiple-selection");
    return value || "";
  };

  const RenderPagination = () => {
    return (dataStatistics?.total_number as any) > filters.per_page ? (
      <Pagination
        current={filters.page}
        onChange={handleChangePage}
        pageSize={filters.per_page}
        total={dataStatistics?.total_number}
        showSizeChanger={false}
        showLessItems={!screens.xl}
      />
    ) : (
      <Fragment></Fragment>
    );
  };

  const handleGetValue = (tree: any, arr: any) => {
    tree.map((item: any) => {
      if (item.checked) {
        arr.push(item.name);
      }
      if (item.sub_departments.length) {
        handleGetValue(item.sub_departments, arr);
      }
    });
  };

  const getIdParent = () => {
    const idParentList = treeData.map((item: any) => {
      return item.id;
    });

    const checkedArr: number[] = [];
    handleGetCheckedItemValue(treeData, checkedArr, "sub_departments");
    const arrID: any = [];

    checkedArr.map((item: any) => {
      if (idParentList.includes(item)) {
        arrID.push(true);
      } else {
        arrID.push(false);
      }
    });

    const check = arrID.some((item: any) => !item);
    return !check && checkedArr.length === idParentList.length;
  };

  const handleValueOption = () => {
    const arr: any = [];
    handleGetValue(treeData, arr);
    if (getIdParent()) return t3("all");
    if (arr.length > 1) return t("multiple-selection");
    return arr[0];
  };

  const handleResetFilter = () => {
    const currentDate = new Date();
    const params: any = {
      page: 1,
      per_page: 150,
      app_language_id: currentLanguage,
      aggregate_date: moment(currentDate).format("YYYY/MM/DD"),
    };
    setFilters(params);
    setCondition([]);
    setOriginalConditionsValue({
      temperature: [],
      activities: [],
      grade: [],
      symptom: [],
      attendance: [],
      smallCheck: [],
    });
    treeData.forEach((item: any) => {
      handleCheckedItem(item, "sub_departments", false);
    });
    setTreeData([...treeData]);
    setIsChangeDateSearch(false);
    setStartDate(new Date());
    dispatch(GetDataStatisticsActionCreators.getStatisticsAction(params));
    handleScrollTableTop();
  };

  const defaultSelects = [
    {
      key: 2,
      label: t2("activities"),
      placeholder: t("selection"),
      onChange: handleOnChangeDefaultConditions("activities"),
      value: originalConditionsValue.activities,
      options: destinationOptions,
      isShow: true,
    },
    {
      key: 4,
      label: t("physical-condition"),
      placeholder: t("selection"),
      onChange: handleOnChangeDefaultConditions("symptom"),
      value: originalConditionsValue.symptom,
      options: symptomsForFilter,
      isShow: getHealthCheckSetting?.health_check_setting?.is_health_check,
    },
    {
      key: 5,
      label: t3("attendance-status"),
      placeholder: t("selection"),
      onChange: handleOnChangeDefaultConditions("attendance"),
      value: originalConditionsValue.attendance,
      options: attendanceOptions,
      isShow:
        !getHealthCheckSetting?.operation_setting?.can_use_heart_and_health &&
        getHealthCheckSetting?.health_check_setting?.is_attendance,
    },
    {
      key: 1,
      label: t("mental-check"),
      placeholder: t("selection"),
      onChange: handleOnChangeDefaultConditions("smallCheck"),
      value: originalConditionsValue.smallCheck,
      options: smallCheckForFilter,
      isShow: getHealthCheckSetting?.health_check_setting?.is_mental_check,
    },
    {
      key: 3,
      label: t3("body-temperature"),
      placeholder: t("selection"),
      onChange: handleOnChangeDefaultConditions("temperature"),
      value: originalConditionsValue.temperature,
      options: temperatureForFilter2,
      isShow: true,
    },
  ];

  return (
    <>
      <CollapseStyled
        header={t("data-download")}
        expandIconPosition="right"
        defaultActiveKey={["1"]}
      >
        <GroupButton />
      </CollapseStyled>
      <CollapseStyled
        header={t("user-refined-search-condition-setting")}
        expandIconPosition="right"
        defaultActiveKey={["1"]}
      >
        <WrapperSearchCondition>
          <SearchConditionContainer>
            <WrapperDatePicker>
              <DatePicker
                type="inline"
                selected={startDate}
                onChange={(date) => {
                  setStartDate(date);
                  if (
                    date?.toISOString().slice(0, 10) !=
                    startDate?.toISOString().slice(0, 10)
                  ) {
                    setIsChangeDateSearch(true);
                  }
                }}
              />
            </WrapperDatePicker>
            <WrapperSearchConditionAction>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                <Select
                  style={
                    handleCheckTreeData()
                      ? { color: "#2AC769", fontWeight: 500 }
                      : { color: "#3B3B3B" }
                  }
                  marginForm="0 15px 16px 0"
                  fs={16}
                  fwLabel={500}
                  width="100px"
                  // options={select.options}
                  value={handleValueOption()}
                  label={t2("grade")}
                  placeholder={t("selection")}
                  dropdownRender={() => (
                    <div
                      style={{
                        padding: "7px 10px 5px",
                        maxHeight: "300px",
                        overflowX: "auto",
                      }}
                    >
                      {treeData.map((item: any) => {
                        return (
                          <WrapperItem key={item.id}>
                            <Item item={item} itemType="sub_departments" />
                          </WrapperItem>
                        );
                      })}
                    </div>
                  )}
                />
                {defaultSelects.map((select: any) => {
                  if (select.isShow) {
                    return (
                      <Select
                        key={select.key}
                        style={
                          select.value.length > 1
                            ? { color: "#2AC769", fontWeight: 500 }
                            : { color: "#3B3B3B" }
                        }
                        marginForm="0 15px 16px 0"
                        fs={16}
                        fwLabel={500}
                        width="100px"
                        options={select.options}
                        value={getValueForSelect2(select.value, select.options)}
                        label={select.label}
                        placeholder={t("selection")}
                        dropdownRender={() => (
                          <div
                            style={{
                              padding: "7px 10px 5px",
                              maxHeight: "300px",
                              overflowX: "auto",
                            }}
                          >
                            <Checkbox1
                              list={select.options}
                              value={select.value}
                              onChange={select.onChange}
                            />
                          </div>
                        )}
                      />
                    );
                  }
                })}
              </div>
              <div
                style={{
                  fontSize: 14,
                  lineHeight: "20px",
                  fontWeight: 500,
                  marginBottom: 6,
                }}
              >
                {t("filter-conditions")}
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <ButtonDisabledStyled
                    color="#1AB759"
                    fontSize={14}
                    fontWeight={500}
                    lineHeight="16px"
                    background="#FFFFFF"
                    padding="3px 5px 4px"
                    name={t1("add-item")}
                    bdr="4px"
                    border="1px solid #1AB759"
                    margin="0 6px 0 0"
                    icon={<PlusIcon fill="#1AB759" />}
                    onClick={handleAddCondition}
                    disabled={conditions.length >= filterList?.length}
                  />
                  <ButtonDisabledStyled
                    color="#FB4E4E"
                    fontSize={14}
                    fontWeight={500}
                    lineHeight="16px"
                    background="#FFFFFF"
                    padding="3px 5px 4px"
                    name={t("delete-item")}
                    bdr="4px"
                    border="1px solid #FB4E4E"
                    icon={<MinusIcon fill="#FB4E4E" />}
                    onClick={handleRemoveCondition}
                    disabled={conditions.length <= 0}
                  />
                </div>
                <Button
                  color="#FFFFFF"
                  fontSize={14}
                  fontWeight={500}
                  lineHeight="16px"
                  background="#2AC769"
                  padding="5px 5px 3px 19px"
                  name={t("reset")}
                  bdr="4px"
                  border="1px solid #E0E0E0"
                  margin={screens.lg ? "0 16px 0" : ""}
                  icon={
                    <ResetIcon
                      style={{
                        position: "absolute",
                        left: 7,
                        top: 5,
                      }}
                    />
                  }
                  onClick={handleResetFilter}
                />
              </div>

              <div style={{ marginTop: 16, display: "flex", flexWrap: "wrap" }}>
                {conditions.map((condition: any, index: number) => {
                  if (!index) {
                    return (
                      <div style={{ display: "flex" }} key={index}>
                        <Fragment>
                          <Select
                            marginForm="0 8px 12px 0"
                            fs={16}
                            fwLabel={500}
                            width="100px"
                            options={filterList}
                            onChange={handleOnChangeFilter(index)}
                            value={condition.key}
                          />
                          <Select
                            style={
                              condition.key && condition.value.length > 1
                                ? { color: "#2AC769", fontWeight: 500 }
                                : { color: "#3B3B3B" }
                            }
                            marginForm="0 8px 12px 0"
                            fs={16}
                            fwLabel={500}
                            width="100px"
                            options={
                              condition.key ? filterData[condition.key] : []
                            }
                            value={getValueForSelect(condition)}
                            dropdownRender={() => (
                              <div
                                style={{
                                  padding: "7px 10px 5px",
                                  maxHeight: "280px",
                                  overflowX: "auto",
                                }}
                              >
                                <Checkbox1
                                  list={
                                    condition.key
                                      ? filterData[condition.key]
                                      : []
                                  }
                                  value={condition.value}
                                  onChange={handleOnChangeFilterData(index)}
                                />
                              </div>
                            )}
                          />
                        </Fragment>
                        {conditions.length > 1 &&
                          index < conditions.length - 1 && (
                            <AndCondition>AND</AndCondition>
                          )}
                      </div>
                    );
                  }
                  return (
                    <div style={{ display: "flex" }} key={index}>
                      <Fragment>
                        <Select
                          marginForm="0 8px 12px 0"
                          fs={16}
                          fwLabel={500}
                          width="100px"
                          options={filterList}
                          onChange={handleOnChangeFilter(index)}
                          value={condition.key}
                        />
                        <Select
                          style={
                            condition.key && condition.value.length > 1
                              ? { color: "#2AC769", fontWeight: 500 }
                              : { color: "#3B3B3B" }
                          }
                          marginForm="0 8px 12px 0"
                          fs={16}
                          fwLabel={500}
                          width="100px"
                          options={
                            condition.key ? filterData[condition.key] : []
                          }
                          value={getValueForSelect(condition)}
                          dropdownRender={() => (
                            <div style={{ padding: "7px 10px 5px" }}>
                              <Checkbox1
                                list={
                                  condition.key ? filterData[condition.key] : []
                                }
                                value={condition.value}
                                onChange={handleOnChangeFilterData(index)}
                              />
                            </div>
                          )}
                        />
                        {conditions.length > 1 &&
                          index < conditions.length - 1 && (
                            <AndCondition>AND</AndCondition>
                          )}
                      </Fragment>
                    </div>
                  );
                })}
              </div>
            </WrapperSearchConditionAction>
          </SearchConditionContainer>
          <WrapperSearchConditionButton>
            <ButtonDisabledStyledWithBackground
              color="#FFFFFF"
              fontSize={16}
              fontWeight={700}
              lineHeight="16px"
              background="#2AC769"
              padding="10px 21px 10px"
              name={t("narrow-down")}
              bdr="6px"
              border="1px solid #E0E0E0"
              disabled={handleBtnStatus()}
              onClick={handleFilter}
            />
          </WrapperSearchConditionButton>
        </WrapperSearchCondition>
      </CollapseStyled>
      <WrapperTableAction>
        <WrapperSizeChange>
          <div style={{ fontWeight: 500 }}>{t2("record")}</div>
          <Select
            defaultValue={150}
            options={recordOptions}
            onChange={handleSizeChange}
          />
        </WrapperSizeChange>
        <WrapperPaginationTop>
          <RenderPagination />
        </WrapperPaginationTop>
      </WrapperTableAction>
      <div
        style={{
          height: "auto",
          width: "100%",
        }}
        ref={scrollAbleRef}
        onMouseOver={() => {
          if (!displayScrollable && firstRender.current) return;
          setDisplayScrollable(true);
          setTimeout(() => {
            setDisplayScrollable(false);
            firstRender.current = true;
          }, 2000);
        }}
      >
        {displayScrollable && !firstRender.current && (
          <ScrollableWrapper>
            {screens.xl ? <ScrollableDesktop /> : <ScrollableTablet />}
          </ScrollableWrapper>
        )}
        <TableStyled
          columns={temperatureManagementColumn}
          dataSource={statisticData}
          actionSection={<ActionSection />}
          scroll={
            dataStatistics?.answers?.length
              ? { x: "max-content", y: 600 }
              : null
          }
          loading={isLoading}
        />
        {!dataStatistics?.answers?.length && (
          <NoDataMessage>{t4("no-data-reflection-list")}</NoDataMessage>
        )}
      </div>

      <WrapperPaginationBottom>
        <RenderPagination />
      </WrapperPaginationBottom>
      {isVisibleAddModal && (
        <AdditionalModal
          isVisible={isVisibleAddModal}
          handleCancel={() => setIsVisibleAddModal(false)}
          patientInfo={patientInfo}
          selectedDate={startDate}
        />
      )}
      {isVisibleDetailModal && (
        <BodyTemperatureDetailModal
          isVisible={isVisibleDetailModal}
          handleCancel={() => setIsVisibleDetailModal(false)}
          patientInfo={patientInfo}
        />
      )}
      {isVisibleSettingModal && (
        <ConditionSettingModal
          isVisible={isVisibleSettingModal}
          handleCancel={() => setIsVisibleSettingModal(false)}
          fileType={fileType}
        />
      )}
      <ConfirmAbsentModal
        handleOk={() => {
          dispatch(
            ApproveAbsenceCreators.approveAbsenceAction({
              id: displayModal.id,
            })
          );
          setDisplayModal({
            ...displayModal,
            isVisible: false,
          });
        }}
        handleCancel={() =>
          setDisplayModal({
            ...displayModal,
            isVisible: false,
          })
        }
        isVisible={displayModal.isVisible}
      >
        <div className="bold">
          <Trans
            i18nKey="body-temperature.mark-absent-question"
            name={displayModal.name}
            absent={t(displayModal.absentType)}
          >
            {{ name: displayModal.name }}さんの
            {{ absent: displayModal.absentType }}
            を確認済みにしますか？
          </Trans>
        </div>
        <div>{t("send-user-message")}</div>
      </ConfirmAbsentModal>
    </>
  );
};
export default SearchTerms;
