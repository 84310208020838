import React, { useState, useEffect } from "react";
import { Grid, Tabs, notification } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import { Button, ScrollToTop, Select } from "components";
import { BackIcon2, SendCompleteIcon } from "icons";
import { httpStatus } from "configs/httpStatus";
import {
  uuid,
  localStorageHelper,
  handleGetTimeByTimeZone,
  handleCondition,
  handleTimeOptions,
} from "utils";

import {
  CreateQuestionnairesActionCreators,
  UpdateQuestionnaireActionCreators,
  DoneQuestionActionCreators,
  AnswerInfoActionCreators,
  IndividualAnswerActionCreators,
  QuestionnairesDetailActionCreators,
  CancelQuestionnaireActionCreators,
} from "redux/rootActions";
import { questionnairesDetailDataResponse } from "redux/questionnaires/questionnairesDetail/questionnairesDetailStates";
import { createQuestionnairesDataResponse } from "redux/questionnaires/createQuestionnaires/createQuestionnairesStates";
import { updateQuestionnaireDataResponse } from "redux/questionnaires/updateQuestionnaires/updateQuestionnairesStates";
import { answerInfoDataResponse } from "redux/questionnaires/answerInfo/answerInfoStates";
import { doneQuestionResponseData } from "redux/questionnaires/doneQuestion/doneQuestionStates";
import { cancelQuestionnaireDataResponse } from "redux/questionnaires/cancelQuestionnaire/cancelQuestionnaireStates";
import {
  IQuestionnaire,
  QuestionnairesQuestion,
} from "models/questionnaires/questionnairesDetail";

import IndividualAnswer from "./IndividualAnswer";
import AnswerTab from "./AnswerTab";
import {
  TabsStyled,
  WrapperTabContent,
  WrapperRedirect,
  WrapperAction,
  Text,
  Tag2,
  SpecialText,
  WrapperActionMobile,
  WrapperHours,
  SelectStyled,
} from "./Questions.style";
import Questionnaire from "./Questionnaire";
import QuestionDraft from "./QuestionDraft";
import { ScreenState } from "./Questions";
import { ConfirmModal, DatePicker, DateRangePicker } from "./components";
import { TextConfirm } from "screens/messageDelivery/MessageDelivery.style";
import {
  timeValueDefaults,
  format,
  formatDateTime,
  timeValueDefaults2,
} from "./const";

const { useBreakpoint } = Grid;
const { TabPane } = Tabs;

interface ICreateQuestion {
  setScreen: React.Dispatch<React.SetStateAction<ScreenState>>;
  setStatus: React.Dispatch<React.SetStateAction<string>>;
  setId: React.Dispatch<React.SetStateAction<any>>;
  id: number;
  status: string;
}

const CreateQuestion = ({
  setScreen,
  status,
  setStatus,
  setId,
  id,
}: ICreateQuestion) => {
  const { t }: any = useTranslation("translation", { keyPrefix: "questions" });
  const { t: t1 }: any = useTranslation("translation", {
    keyPrefix: "user-list",
  });
  const { t: t2 }: any = useTranslation("translation", {
    keyPrefix: "vaccination",
  });

  const currentLanguage = localStorageHelper.getItem("i18nextLng");
  const currentTimeZone = localStorageHelper.getItem("currentTimeZone");

  const screens = useBreakpoint();
  const dispatch = useDispatch();
  const questionnairesDetailData = useSelector(
    questionnairesDetailDataResponse
  );
  const createQuestionnairesData = useSelector(
    createQuestionnairesDataResponse
  );
  const updateQuestionnaireData = useSelector(updateQuestionnaireDataResponse);
  const answerInfoResponse = useSelector(answerInfoDataResponse);
  const doneQuestionResponse = useSelector(doneQuestionResponseData);
  const cancelQuestionnaireData = useSelector(cancelQuestionnaireDataResponse);

  const [questionnaires, setQuestionnaires] = useState<IQuestionnaire>({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<any>({
    title: "",
    explanation: "",
    questions: "",
    questionnaire_questions: [],
  });
  const [isVisibleModal, setIsVisibleModal] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<any>();
  const [endDate, setEndDate] = useState<any>();
  const [startDateTime, setStartDateTime] = useState<any>("00:00");
  const [endDateTime, setEndDateTime] = useState<any>("00:00");
  const [
    isVisibleConfirmTimeSettingModal,
    setIsVisibleConfirmTimeSettingModal,
  ] = useState(false);
  const [isVisibleStartTimeConfirm, setIsVisibleStartTimeConfirm] =
    useState(false);
  const [endTimeOptions, setEndTimeOptions] = useState<any>(timeValueDefaults);
  const [startTimeOptions, setStartTimeOptions] = useState(timeValueDefaults);
  const [minDate, setMinDate] = useState(startDate);
  const [startDateOfEndDatePicker, setStartDateOfEndDatePicker] =
    useState(startDate);

  useEffect(() => {
    if (questionnaires && Object.keys(questionnaires).length) {
      if (questionnaires.start_time) {
        const [dateNow, timeNow] = handleGetTimeByTimeZone(
          formatDateTime,
          currentTimeZone,
          new Date()
        ).split(" ");

        const [date, time] = handleGetTimeByTimeZone(
          formatDateTime,
          currentTimeZone,
          questionnaires.start_time
        ).split(" ");
        setStartDate(new Date(date) as any);
        setStartDateTime(time);
        if (time === "23:00") {
          const dateValue = moment(new Date(date)).add(1, "d").toDate();
          setMinDate(dateValue);
          setStartDateOfEndDatePicker(dateValue);
        } else {
          setMinDate(new Date(date) as any);
          setStartDateOfEndDatePicker(new Date(date) as any);
        }
        const [hour] = timeNow.split(":");

        if (dateNow === date) {
          setStartTimeOptions(handleTimeOptions(Number(hour) + 2));
        } else {
          setStartTimeOptions(timeValueDefaults);
        }
      }
      if (questionnaires.end_time) {
        const end = handleGetTimeByTimeZone(
          formatDateTime,
          currentTimeZone,
          questionnaires.end_time
        ).split(" ");
        setEndDate(new Date(end[0]) as any);
        setEndDateTime(end[1]);
      }
      if (questionnaires.start_time && questionnaires.end_time) {
        const start = handleGetTimeByTimeZone(
          formatDateTime,
          currentTimeZone,
          questionnaires.start_time
        ).split(" ");
        const end = handleGetTimeByTimeZone(
          formatDateTime,
          currentTimeZone,
          questionnaires.end_time
        ).split(" ");
        if (start[0] === end[0]) {
          const [hour] = start[1].split(":");
          const hourValue = Number(hour) + 1;
          setEndTimeOptions(handleTimeOptions(hourValue));
        } else {
          setEndTimeOptions(timeValueDefaults);
        }
      }
    }
  }, [questionnaires]);

  const handleEndSurvey = () => {
    setIsVisibleModal(false);
    dispatch(
      DoneQuestionActionCreators.doneQuestionAction({
        id: questionnaires?.id,
      })
    );
  };

  useEffect(() => {
    if (
      questionnairesDetailData &&
      Object.keys(questionnairesDetailData.result).length &&
      status !== "create"
    ) {
      const errorArr: any = [];
      questionnairesDetailData?.result?.questionnaire?.questionnaire_questions?.forEach(
        (question) => {
          let err: any = {
            id: uuid(),
            questionnaire_answers: [],
          };
          if (!question.question_content) {
            err = {
              ...err,
              question_content: t("enter-question-name"),
            };
          }
          if (question.is_free_text && !question.question_summary) {
            err = {
              ...err,
              question_summary: t("enter-question-explanation"),
            };
          }
          if (!question.answer_type) {
            err = {
              ...err,
              answer_type: t("please-choose-question-type"),
            };
          } else {
            if (
              question.answer_type === "radio" ||
              question.answer_type === "checkbox" ||
              question.answer_type === "pulldown"
            ) {
              if (question.questionnaire_answers.length) {
                question.questionnaire_answers.forEach((ans: any) => {
                  if (!ans.is_other) {
                    if (ans.answer_text) {
                      err.questionnaire_answers.push({
                        id: uuid(),
                        answer_text: "",
                        is_active: false,
                      });
                    } else {
                      err.questionnaire_answers.push({
                        id: uuid(),
                        answer_text: t("enter-answer"),
                        is_active: false,
                      });
                    }
                  } else {
                    err.questionnaire_answers.push({
                      id: uuid(),
                    });
                  }
                });
              }
            }
          }

          errorArr.push(err);
        }
      );

      setQuestionnaires(questionnairesDetailData.result.questionnaire);
      setError((prevState: any) => ({
        ...prevState,
        questionnaire_questions: errorArr,
      }));

      const { id } = questionnairesDetailData.result.questionnaire;
      dispatch(
        AnswerInfoActionCreators.getAnswerInfoAction({
          id,
        })
      );
      dispatch(
        IndividualAnswerActionCreators.getIndividualAnswerAction({
          id,
        })
      );
      setId(id);
    }

    return () => {
      dispatch(CreateQuestionnairesActionCreators.handleResetAction());
    };
  }, [questionnairesDetailData, status]);

  useEffect(() => {
    if (
      updateQuestionnaireData &&
      updateQuestionnaireData.status === httpStatus.StatusOK
    ) {
      notification.open({
        message: moment(new Date()).format(formatDateTime),
        description: t("draft-is-saved"),
        icon: <SendCompleteIcon />,
        placement: "bottomRight",
      });
      setIsLoading(false);
      setIsVisibleStartTimeConfirm(false);
      dispatch(UpdateQuestionnaireActionCreators.handleResetAction());
    }

    if (
      createQuestionnairesData &&
      createQuestionnairesData.status === httpStatus.StatusOK &&
      !id
    ) {
      notification.open({
        message: moment(new Date()).format(formatDateTime),
        description: t("draft-is-saved"),
        icon: <SendCompleteIcon />,
        placement: "bottomRight",
      });
      setIsLoading(false);
      setStatus("update");
      dispatch(
        QuestionnairesDetailActionCreators.getQuestionnaireDetailAction({
          id: createQuestionnairesData.result.questionnaire_id,
        })
      );
      dispatch(CreateQuestionnairesActionCreators.handleResetAction());
    }

    if (
      doneQuestionResponse &&
      doneQuestionResponse.status === httpStatus.StatusOK
    ) {
      notification.success({
        placement: "bottomRight",
        message: t("done-message"),
        icon: <SendCompleteIcon />,
      });
      dispatch(DoneQuestionActionCreators.handleResetAction());
      setScreen("");
    }

    if (
      cancelQuestionnaireData &&
      cancelQuestionnaireData.status === httpStatus.StatusOK
    ) {
      dispatch(CancelQuestionnaireActionCreators.handleResetAction());
      dispatch(
        QuestionnairesDetailActionCreators.getQuestionnaireDetailAction({
          id: id,
        })
      );
      setIsVisibleConfirmTimeSettingModal(false);
      setStatus("update");
    }
  }, [
    createQuestionnairesData,
    updateQuestionnaireData,
    doneQuestionResponse,
    cancelQuestionnaireData,
  ]);

  useEffect(() => {
    if (id && updateQuestionnaireData) {
      dispatch(
        QuestionnairesDetailActionCreators.getQuestionnaireDetailAction({
          id: id,
        })
      );
    }
  }, [id, updateQuestionnaireData]);

  const handleCheckString = (value: any) => {
    return typeof value === "string";
  };

  const handleCheckAnswers = (answers: any) => {
    return answers.map((answer: any) => {
      let obj = {};
      for (const property in answer) {
        if (answer[property] !== undefined) {
          if (property === "id" && !handleCheckString(answer[property])) {
            obj = {
              ...obj,
              [property]: answer[property],
            };
          }

          if (property !== "id") {
            obj = {
              ...obj,
              [property]: answer[property],
            };
          }
        }
      }
      return obj;
    });
  };

  const handleCheckQuestion = (questionArr: QuestionnairesQuestion[]) => {
    let isValid = false;
    questionArr
      .filter((question: QuestionnairesQuestion) => !question._destroy)
      .forEach((question: QuestionnairesQuestion) => {
        if (!question.answer_type) isValid = true;
        if (!question.question_content) isValid = true;
        if (question.is_free_text && !question.question_summary) isValid = true;
        if (
          question.answer_type !== "text" &&
          question.answer_type !== "multiLine"
        ) {
          if (!question.questionnaire_answers.length) {
            isValid = true;
          } else {
            const arr = question.questionnaire_answers;
            if (arr.length) {
              arr.forEach((ans: any) => {
                if (!ans.answer_text) isValid = true;
              });
            } else {
              isValid = true;
            }
          }
        }
      });

    return isValid;
  };

  const handleSortAnswerQuestion = (questionArr?: any) => {
    const clone = [...questionArr];
    return clone.sort(
      (a: any, b: any) => Number(a.is_other) - Number(b.is_other)
    );
  };

  const handleQuestionAttributes = () => {
    return (questionnaires.questionnaire_questions || []).map(
      (question: any, idx) => {
        let obj: any = {};
        for (const property in question) {
          if (question[property] !== undefined) {
            if (property !== "id" && property !== "questionnaire_answers") {
              obj = {
                ...obj,
                [property]: question[property],
              };
            }

            if (property === "id" && !handleCheckString(question[property])) {
              obj = {
                ...obj,
                [property]: question[property],
              };
            }

            if (
              property === "questionnaire_answers" &&
              question[property].length
            ) {
              let ans = question[property];

              if (handleCheckString(question.id)) {
                ans = question[property].map((a: any) => {
                  const { id, ...rest } = a;

                  return rest;
                });
              }

              obj = {
                ...obj,
                questionnaire_answers_attributes: handleCheckAnswers(ans),
              };
            }
          }
        }

        return {
          ...obj,
          question_position: idx + 1,
        };
      }
    );
  };

  const handleSaveDraft = (send?: boolean) => {
    if (!questionnaires.title) {
      setError((prevState: any) => ({
        ...prevState,
        title: "enter-title",
      }));
      return;
    }

    if (!questionnaires.explanation) {
      setError((prevState: any) => ({
        ...prevState,
        explanation: "enter-explanation",
      }));
      return;
    }

    if (
      !questionnaires.questionnaire_questions ||
      questionnaires.questionnaire_questions.every((item: any) => item._destroy)
    ) {
      setError((prevState: any) => ({
        ...prevState,
        questions: "enter-question",
      }));
      return;
    }

    const errorArr: any = [];

    if (handleCheckQuestion(questionnaires.questionnaire_questions)) {
      questionnaires.questionnaire_questions.forEach(
        (question, idx: number) => {
          let err: any = {
            ...error.questionnaire_questions[idx],
            questionnaire_answers: [],
          };
          if (!question.question_content) {
            err = {
              ...err,
              question_content: t("enter-question-name"),
            };
          }
          if (question.is_free_text && !question.question_summary) {
            err = {
              ...err,
              question_summary: t("enter-question-explanation"),
            };
          }
          if (!question.answer_type) {
            err = {
              ...err,
              answer_type: t("please-choose-question-type"),
            };
          } else {
            if (
              question.answer_type === "radio" ||
              question.answer_type === "checkbox" ||
              question.answer_type === "pulldown"
            ) {
              if (error.questionnaire_questions.length) {
                error.questionnaire_questions.forEach(
                  (ques: any, idxErrQuestion: number) => {
                    if (idx === idxErrQuestion) {
                      if (ques.questionnaire_answers.length) {
                        handleSortAnswerQuestion(
                          ques.questionnaire_answers
                        ).forEach((asw: any) => {
                          if (asw.answer_text) {
                            err.questionnaire_answers.push({
                              ...asw,
                              is_active: true,
                            });
                          } else {
                            err.questionnaire_answers.push(asw);
                          }
                        });
                      }
                    }
                  }
                );
              }
            }
          }

          errorArr.push(err);
        }
      );

      setError((prevState: any) => ({
        ...prevState,
        questionnaire_questions: errorArr,
      }));

      return;
    }

    setIsLoading(true);

    let questionnaireParam: any = {
      title: questionnaires.title,
      explanation: questionnaires.explanation,
      questionnaire_questions_attributes: handleQuestionAttributes(),
    };

    if (status === "view-detail") {
      questionnaireParam = {
        ...questionnaireParam,
        end_time: endDate,
        start_time: startDate,
      };
    }

    const param: any = {
      questionnaire: questionnaireParam,
    };

    if (status === "create" && !id) {
      dispatch(
        CreateQuestionnairesActionCreators.createQuestionnairesAction(param)
      );
    }

    if (status === "update" || status === "view-detail" || id) {
      dispatch(
        UpdateQuestionnaireActionCreators.updateQuestionnaireAction({
          id: questionnaires.id || id,
          param,
        })
      );
      setId(questionnaires?.id);
    }

    if (send) setScreen("transmission");
  };

  const handleUpdatePeriod = (start: string, end: string) => {
    const param = {
      questionnaire: {
        title: questionnaires.title,
        explanation: questionnaires.explanation,
        questionnaire_questions_attributes: handleQuestionAttributes(),
        end_time: end,
        start_time: start,
      },
    };

    dispatch(
      UpdateQuestionnaireActionCreators.updateQuestionnaireAction({
        id: questionnaires.id || id,
        param,
      })
    );
  };

  const handleOnSelectStartDate = (date: Date) => {
    let start = moment(date).format(format);
    const now = handleGetTimeByTimeZone(format, currentTimeZone, new Date());
    let end = moment(endDate).format(format);

    let startTimeDefault = "00:00";
    let endTimeDefault = endDateTime;

    if (start === now) {
      const currentHour =
        moment(
          handleGetTimeByTimeZone(
            "YYYY/MM/DD HH:mm",
            currentTimeZone,
            new Date()
          )
        ).hour() + 2;

      startTimeDefault = `${currentHour}:00`;
      setStartTimeOptions(handleTimeOptions(currentHour));
      setStartDateTime(startTimeDefault);
    } else {
      setStartTimeOptions(handleTimeOptions(timeValueDefaults));
      setStartDateTime(startTimeDefault);
    }

    if (start === end) {
      const [hour] = startTimeDefault.split(":");
      const hourValue = Number(hour) + 1;
      let hourDefaultValue = `${hourValue}:00`;
      if (hourValue <= 9) {
        hourDefaultValue = `0${hourValue}:00`;
      }
      setEndDateTime(hourDefaultValue);
      setEndTimeOptions(handleTimeOptions(hourValue));
      endTimeDefault = hourDefaultValue;
    }

    start += ` ${startTimeDefault}`;
    end += ` ${endTimeDefault}`;
    handleUpdatePeriod(start, end);
  };

  const handleOnSelectEndDate = (date: Date) => {
    const endTimeDefault = "23:00";
    const now = handleGetTimeByTimeZone(format, currentTimeZone, new Date());
    let end = moment(date).format(format);
    let start = moment(startDate).format(format);

    const [hour] = startDateTime.split(":");

    if (start === end) {
      setEndTimeOptions(handleTimeOptions(Number(hour) + 1));
    } else {
      setEndTimeOptions(timeValueDefaults);
    }

    if (end === now) {
      setEndDateTime(endTimeDefault);

      end += ` ${endTimeDefault}`;
    } else {
      setEndDateTime(endTimeDefault);

      end += ` ${endTimeDefault}`;
    }

    start += ` ${startDateTime}`;
    handleUpdatePeriod(start, end);
  };

  const handleOnChangeStartDateTime = (value: string) => {
    let start = moment(startDate).format(format);
    let end = moment(endDate).format(format);

    if (value === "23:00") {
      if (start === end) {
        const dateValue = moment(startDate).add(1, "d").toDate();

        setMinDate(dateValue);
        setStartDateOfEndDatePicker(dateValue);
        setEndDate(dateValue);
        setEndDateTime("00:00");
        setEndTimeOptions(timeValueDefaults);

        end = moment(dateValue).format(format) + " " + "00:00";
      } else {
        end += " " + endDateTime;
      }
    } else {
      if (start === end) {
        const [hour] = value.split(":");
        const hourValue = Number(hour) + 1;
        let hourDefaultValue = `${hourValue}:00`;
        if (hourValue <= 9) {
          hourDefaultValue = `0${hourValue}:00`;
        }
        setEndDateTime(hourDefaultValue);
        end += " " + hourDefaultValue;
      } else {
        end += " " + endDateTime;
      }
      setMinDate(startDate);
      setStartDateOfEndDatePicker(startDate);
    }
    start += " " + value;
    handleUpdatePeriod(start, end);

    // if (start > now) {
    //   start += " " + value;
    //   end += " " + endDateTime;
    //   handleUpdatePeriod(start, end);
    // } else {
    //   now = handleGetTimeByTimeZone(
    //     formatDateTime,
    //     currentTimeZone,
    //     new Date()
    //   );
    //   const nowHour = moment(now).hour() + ":00";

    //   if (value <= nowHour) {
    //     setIsVisibleStartTimeConfirm(true);
    //   } else {
    //     start += " " + value;
    //     end += " " + endDateTime;
    //     handleUpdatePeriod(start, end);
    //   }
    // }

    setStartDateTime(value);
  };

  const handleOnChangeEndDateTime = (value: string) => {
    const start = moment(startDate).format(format) + " " + startDateTime;
    const end = moment(endDate).format(format) + " " + value;

    setEndDateTime(value);
    handleUpdatePeriod(start, end);
  };

  useEffect(() => {
    if (status === "create") {
      setQuestionnaires({});
    }
  }, [status]);

  const ActionWeb = () => {
    // th1 endDate, startDate null
    if (!questionnaires.start_time) {
      return (
        <div style={{ flex: 1 }}>
          <Button
            onClick={() => handleSaveDraft(true)}
            type="submit"
            name={t("send-a-survey")}
            background={"#2AC769"}
            color="#FFFFFF"
            border="none"
            fontSize={16}
            fontWeight={700}
            padding="7px 24px"
            width={screens.xs ? "100%" : "auto"}
          />
        </div>
      );
    }

    if (
      handleCondition("th1", questionnaires.start_time, questionnaires.end_time)
    ) {
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ minWidth: "fit-content" }}>
            {t("implementation-period")}：
          </div>
          <DateRangePicker
            onSelectStartDate={handleOnSelectStartDate}
            onSelectEndDate={handleOnSelectEndDate}
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            startDateTime={startDateTime}
            endDateTime={endDateTime}
            locale={currentLanguage}
            maxDate={false}
            minDate={minDate}
            onChangeStartDateTime={handleOnChangeStartDateTime}
            onChangeEndDateTime={handleOnChangeEndDateTime}
            endTimeOptions={endTimeOptions}
            startTimeOptions={startTimeOptions}
            startDateOfEndDatePicker={startDateOfEndDatePicker}
          />
          <Text
            onClick={() => setIsVisibleConfirmTimeSettingModal(true)}
            style={{ marginLeft: screens.lg ? 24 : 40 }}
          >
            {t("do-not-set-the-period")}
          </Text>
        </div>
      );
    }

    if (
      handleCondition("th2", questionnaires.start_time, questionnaires.end_time)
    ) {
      return (
        <>
          <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <div style={{ minWidth: "fit-content" }}>
              {t("implementation-period")}：
            </div>
            <div>{moment(startDate).format(format)}</div>
            <div>~</div>
            <DatePicker
              onChange={(date: any) => setEndDate(date)}
              selected={endDate}
              iconPosition="right"
              minDate={startDate}
              onSelect={handleOnSelectEndDate}
              startDate={startDate}
              endDate={endDate}
              placeholderText="YYYY/MM/DD"
            />
            <div style={{ minWidth: "fit-content" }}>{t2("end-time")}</div>
            <SelectStyled
              width="90px"
              fsLabel={16}
              value={endDateTime}
              onChange={handleOnChangeEndDateTime}
              options={timeValueDefaults}
            />
          </div>
          <div style={{ flex: 1 }}>
            <Button
              onClick={() => setIsVisibleModal(true)}
              type="submit"
              name={t("close-draft")}
              background="#FB4E4E"
              color="#FFFFFF"
              border="none"
              fontSize={16}
              fontWeight={700}
              padding="7px 24px"
              width={screens.xs ? "100%" : "auto"}
            />
          </div>
        </>
      );
    }

    return (
      <>
        <div>
          {t("implementation-period")}：
          <span
            style={{
              fontWeight: 500,
              fontSize: 16,
              lineHeight: "18px",
              color: "#3B3B3B",
            }}
          >
            {moment(startDate).format(format)}
            <span style={{ margin: "0 5px" }}>〜</span>
            {moment(endDate).format(format)}
          </span>
        </div>
      </>
    );
  };

  const ActionMobile = () => {
    if (!questionnaires.start_time) {
      return (
        <WrapperActionMobile>
          <Button
            onClick={() => handleSaveDraft(true)}
            type="submit"
            name={t("send-a-survey")}
            background={"#2AC769"}
            color="#FFFFFF"
            border="none"
            fontSize={16}
            fontWeight={700}
            padding="7px 24px"
            width={screens.xs ? "100%" : "auto"}
          />
        </WrapperActionMobile>
      );
    }

    if (
      handleCondition("th1", questionnaires.start_time, questionnaires.end_time)
    ) {
      return (
        <WrapperActionMobile>
          <div>
            <div className="period-text">{t("implementation-period")}：</div>
            <DateRangePicker
              onSelectStartDate={handleOnSelectStartDate}
              onSelectEndDate={handleOnSelectEndDate}
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              locale={currentLanguage}
              maxDate={false}
              minDate={true}
              handleSaveDraft={handleSaveDraft}
            />
            <WrapperHours>
              <SelectStyled
                label={t2("start")}
                width="140px"
                fsLabel={16}
                value={startDateTime}
                onChange={handleOnChangeStartDateTime}
                options={timeValueDefaults}
              />
              <SelectStyled
                label={t2("end-time")}
                width="140px"
                fsLabel={16}
                value={endDateTime}
                onChange={handleOnChangeEndDateTime}
                options={timeValueDefaults}
              />
            </WrapperHours>
            <Text onClick={() => setIsVisibleConfirmTimeSettingModal(true)}>
              {t("do-not-set-the-period")}
            </Text>
          </div>
        </WrapperActionMobile>
      );
    }

    if (
      handleCondition("th2", questionnaires.start_time, questionnaires.end_time)
    ) {
      return (
        <>
          <WrapperActionMobile style={{ marginBottom: 24 }}>
            <Button
              onClick={() => setIsVisibleModal(true)}
              type="submit"
              name={t("close-draft")}
              background="#FB4E4E"
              color="#FFFFFF"
              border="none"
              fontSize={16}
              fontWeight={700}
              padding="7px 24px"
              width={screens.xs ? "100%" : "auto"}
            />
          </WrapperActionMobile>
          <WrapperActionMobile>
            <div>
              <div className="period-text">{t("implementation-period")}：</div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: 16,
                }}
              >
                <div
                  style={{
                    width: 139,
                    borderRadius: 8,
                    background: "#F2F2F2",
                    padding: "6px 21px 5px",
                  }}
                >
                  {moment(startDate).format(format)}
                </div>
                <div>~</div>
                <div style={{ width: 140 }}>
                  <DatePicker
                    onChange={(date: any) => setEndDate(date)}
                    selected={endDate}
                    iconPosition="right"
                    minDate={startDate}
                    onSelect={handleOnSelectEndDate}
                    placeholderText="YYYY/MM/DD"
                  />
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <SelectStyled
                  label={t2("start")}
                  width="140px"
                  fsLabel={16}
                  value={startDateTime}
                  onChange={handleOnChangeStartDateTime}
                  options={timeValueDefaults}
                />
              </div>
            </div>
          </WrapperActionMobile>
        </>
      );
    }

    return (
      <WrapperActionMobile>
        <div>
          {t("implementation-period")}：
          <span
            style={{
              fontWeight: 500,
              fontSize: 16,
              lineHeight: "18px",
              color: "#3B3B3B",
            }}
          >
            {moment(startDate).format(format)}
            <span style={{ margin: "0 5px" }}>〜</span>
            {moment(endDate).format(format)}
          </span>
        </div>
      </WrapperActionMobile>
    );
  };

  const TagStatus = () => {
    if (!questionnaires?.start_time) {
      return <Tag2 background="#EBC300">{t("draft")}</Tag2>;
    }

    if (
      handleCondition("th1", questionnaires.start_time, questionnaires.end_time)
    ) {
      return <Tag2 background="#2AC769">{t("before-answer")}</Tag2>;
    }

    if (
      handleCondition("th2", questionnaires.start_time, questionnaires.end_time)
    ) {
      return <Tag2 background="#83BCE2">{t("answering")}</Tag2>;
    }

    return <Tag2 background="#CCCCCC">{t("end-of-answer")}</Tag2>;
  };

  return (
    <React.Fragment>
      <ScrollToTop />
      <WrapperRedirect>
        <div className="bor-backicon" onClick={() => setScreen("")}>
          <BackIcon2 />
        </div>
        {status === "create" ? (
          <p>{t("untitled-survey")}</p>
        ) : (
          <p>{questionnaires.title}</p>
        )}
        <TagStatus />
      </WrapperRedirect>

      {screens.xs ? (
        <ActionMobile />
      ) : (
        <WrapperAction>
          <ActionWeb />
        </WrapperAction>
      )}

      <WrapperTabContent>
        <TabsStyled defaultActiveKey="1" type="card" size="small">
          <TabPane tab={t("question")} key="1">
            {status === "view-detail" ? (
              <Questionnaire questionnaires={questionnaires} />
            ) : (
              <QuestionDraft
                questionnaires={questionnaires}
                setQuestionnaires={setQuestionnaires}
                handleSaveDraft={handleSaveDraft}
                isLoading={isLoading}
                setError={setError}
                error={error}
                status={status}
              />
            )}
          </TabPane>
          <TabPane
            tab={
              <div style={{ textAlign: "center" }}>
                {t("answer")}
                {status === "view-detail" && (
                  <SpecialText>
                    {answerInfoResponse?.result?.total_patient_answered}
                  </SpecialText>
                )}
              </div>
            }
            key="2"
          >
            <AnswerTab questionnaires={questionnaires} status={status} />
          </TabPane>
          <TabPane tab={t("individual-answer")} key="3">
            <IndividualAnswer questionID={questionnaires.id} status={status} />
          </TabPane>
        </TabsStyled>
      </WrapperTabContent>
      {isVisibleModal && (
        <ConfirmModal
          isVisible={isVisibleModal}
          close={() => {
            setIsVisibleModal(false);
          }}
          ok={handleEndSurvey}
        >
          <TextConfirm>
            {t("confirm-close-draft")}
            <br></br>
            {t1("are-you-sure")}
          </TextConfirm>
        </ConfirmModal>
      )}
      {isVisibleConfirmTimeSettingModal && (
        <ConfirmModal
          isVisible={isVisibleConfirmTimeSettingModal}
          close={() => {
            setIsVisibleConfirmTimeSettingModal(false);
          }}
          ok={() => {
            dispatch(
              CancelQuestionnaireActionCreators.cancelQuestionnairesAction({
                id: questionnaires.id || id,
              })
            );
          }}
        >
          <TextConfirm>
            {t("if-select-dont-set")}
            <br></br>
            {t("setting-and-desinations-reset")}
            <br></br>
            {t1("are-you-sure")}
          </TextConfirm>
        </ConfirmModal>
      )}
      {isVisibleStartTimeConfirm && (
        <ConfirmModal
          isVisible={isVisibleStartTimeConfirm}
          close={() => {
            setIsVisibleStartTimeConfirm(false);
          }}
          ok={() => {
            const start =
              moment(startDate).format(format) + " " + startDateTime;
            const end = moment(endDate).format(format) + " " + endDateTime;
            handleUpdatePeriod(start, end);
          }}
        >
          <TextConfirm>
            {t("start-time-confirm")}
            <br></br>
            {t("the-survey-sent-immediately")}
            <br></br>
            {t1("are-you-sure")}
          </TextConfirm>
        </ConfirmModal>
      )}
    </React.Fragment>
  );
};

export default CreateQuestion;
