import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Radio, Grid } from "antd";
import moment from "moment";

import { Button } from "components";
import { httpStatus } from "configs/httpStatus";
import {
  CloseIcon,
  ConfirmSuccessIcon,
  ConfirmFailIcon,
  LoaderIcon,
} from "icons";
import {
  localStorageHelper,
  handleGetTimeByTimeZone,
  handleTimeOptions,
} from "utils";

import { SendQuestionnaireActionCreators } from "redux/rootActions";
import { ISendQuestionnaireData } from "models/questionnaires/sendQuestionnaires";
import {
  SaveQuestionnaireDataResponse,
  selectSaveErrorMessage,
  selectIsSendingQuestionnaire,
} from "redux/questionnaires/sendQuestionnaire/sendQuestionnaireStates";

import {
  Question,
  TagsContainer,
  WrapperSelectPeriod,
  Line,
  SendMessageModalStyled,
  WrapperPeriod,
  WrapperSendImmediately,
} from "./Questions.style";
import { DateRangePicker } from "./components";
import {
  Text,
  ConfirmContainer,
  TextConfirm,
  ButtonContainer,
  SectionContainer,
  Label,
  BadgeContainer,
  ConfirmModalStyled,
  ConfirmBtnStyled,
  FailText,
  SendAllBadge,
} from "../messageDelivery/MessageDelivery.style";
import { timeValueDefaults, format } from "./const";

const { useBreakpoint } = Grid;

interface ISendMessageModal {
  isVisible: boolean;
  isSendAll: boolean;
  selectedRowKeys: number[];
  selectedRows: any;
  handleCancel: () => void;
  handleRemoveDestination: (id: number) => void;
  setScreen: (screen: string) => void;
  record: any;
}

interface Question {
  question: string;
  answer: string;
}

const ConfirmTransmissionModal: React.FC<ISendMessageModal> = ({
  isVisible,
  isSendAll,
  handleCancel,
  selectedRowKeys,
  selectedRows,
  handleRemoveDestination,
  setScreen,
  record,
}) => {
  const { t }: any = useTranslation("translation", {
    keyPrefix: "message-delivery",
  });
  const { t: t1 }: any = useTranslation("translation", {
    keyPrefix: "user-list",
  });
  const { t: t2 }: any = useTranslation("translation", {
    keyPrefix: "questions",
  });

  const dispatch = useDispatch();
  const { handleSubmit } = useForm();
  const currentLanguage = localStorageHelper.getItem("i18nextLng");
  const currentTimeZone = localStorageHelper.getItem("currentTimeZone");

  const screens = useBreakpoint();

  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [isVisibleNotification, setIsVisibleNotification] = useState({
    type: "",
    status: false,
  });
  const [isDisabled, setIsDisabled] = useState(false);
  const [value, setValue] = useState("future");
  const [startDate, setStartDate] = useState(moment().add(1, "d").toDate());
  const [endDate, setEndDate] = useState(moment().add(16, "d").toDate());
  const [startDateTime, setStartDateTime] = useState<any>("00:00");
  const [endDateTime, setEndDateTime] = useState<any>("00:00");
  const [endTimeOptions, setEndTimeOptions] = useState<any>(timeValueDefaults);
  const [minDate, setMinDate] = useState(startDate);
  const [startDateOfEndDatePicker, setStartDateOfEndDatePicker] =
    useState(startDate);
  const [timeValue, setTimeValue] = useState(timeValueDefaults);

  const onChange = (e: any) => {
    setValue(e.target.value);
  };

  const sendQuestionnaireSuccess = useSelector(SaveQuestionnaireDataResponse);
  const sendQuestionnaireError = useSelector(selectSaveErrorMessage);
  const sendQuestionnaireLoading = useSelector(selectIsSendingQuestionnaire);

  useEffect(() => {
    dispatch(SendQuestionnaireActionCreators.handleResetDataAction());
  }, []);

  const handleDisabledBtn = (id: number) => {
    const selectedRowKeysClone = selectedRowKeys.filter(
      (key: number) => key !== id
    );
    if (!selectedRowKeysClone.length) {
      setIsDisabled(true);
    }
  };

  const onSendQuestionnaireAPI = () => {
    let params: ISendQuestionnaireData = {
      patient_id: selectedRowKeys,
      questionnaire_id: record?.id,
      is_send_all: isSendAll,
      is_send_immediately: true,
    };

    if (value === "future") {
      const start = moment(startDate).format(format) + " " + startDateTime;
      const end = moment(endDate).format(format) + " " + endDateTime;

      params = {
        ...params,
        is_send_immediately: false,
        start_time: start,
        end_time: end,
      };
    }

    dispatch(SendQuestionnaireActionCreators.sendQuestionnaireAction(params));
  };

  const handleOnChangeStartDateTime = (value: string) => {
    setEndDate(moment(startDate).add(15, "d").toDate());
    setEndDateTime("00:00");
    setEndTimeOptions(timeValueDefaults);

    if (value === "23:00") {
      const dateValue = moment(startDate).add(1, "d").toDate();
      setMinDate(dateValue);
      setStartDateOfEndDatePicker(dateValue);
    } else {
      setMinDate(startDate);
      setStartDateOfEndDatePicker(startDate);
    }

    setStartDateTime(value);
  };

  const handleOnChangeEndDateTime = (value: string) => {
    setEndDateTime(value);
  };

  const handleCancelSendMessageModal = () => {
    handleCancel();
    // setStartDateTime("08:00");
  };

  const handleConfirmMessage = () => {
    setIsVisibleNotification({
      ...isVisibleNotification,
      type: "success",
      status: true,
    });
  };
  const handleFailMessage = () => {
    setIsVisibleNotification({
      ...isVisibleNotification,
      type: "fail",
      status: true,
    });
  };

  const handleCancelNotication = () => {
    setIsVisibleNotification({ type: "", status: false });
    dispatch(SendQuestionnaireActionCreators.handleResetDataAction());
    handleCancelSendMessageModal();
  };

  const handleCancelConfirmMessage = () => {
    setShowConfirm(false);
  };
  useEffect(() => {
    if (
      sendQuestionnaireSuccess &&
      sendQuestionnaireSuccess.status === httpStatus.StatusOK
    ) {
      handleConfirmMessage();
    } else if (sendQuestionnaireError) {
      handleFailMessage();
    } else {
      handleCancelConfirmMessage();
    }
  }, [sendQuestionnaireSuccess, sendQuestionnaireError]);

  const SuccessModal = () => (
    <ConfirmModalStyled
      type="form"
      title=""
      visible={isVisibleNotification.status}
      onCancel={() => {
        handleCancelNotication();
        setScreen("list");
      }}
    >
      <div style={{ textAlign: "center", marginTop: 24 }}>
        <ConfirmSuccessIcon />
        <Text
          size={36}
          lnHeight="36px"
          weight={700}
          color="#2ac769"
          mg="20px 0px 24px"
        >
          {value !== "future"
            ? t("send-completely")
            : t2("reservation-completed-title")}
        </Text>
        <Text size={16} lnHeight="24px" weight={400}>
          {value !== "future"
            ? t("the-transmission-completed")
            : t2("reservation-completed-text")}
        </Text>
        <Text size={16} lnHeight="24px" weight={400} mg="0px 0px 24px">
          <span
            style={{
              color: "#2F8CAE",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => {
              handleCancelNotication();
              setScreen("list");
            }}
          >
            {t2("questions-list-page")}
          </span>

          {value !== "future" ? t2("check-result") : t2("confirm-reservation")}
        </Text>
        <ConfirmBtnStyled
          background="#E0E0E0"
          color="#FFFFFF"
          padding="11px 96px 13px"
          name={t("close-up")}
          border="none"
          bdr="6px"
          fontSize={16}
          lineHeight="16px"
          onClick={() => {
            handleCancelNotication();
            setScreen("list");
          }}
        />
      </div>
    </ConfirmModalStyled>
  );

  const FailureModal = () => (
    <ConfirmModalStyled
      type="form"
      title=""
      visible={isVisibleNotification.status}
      onCancel={handleCancelNotication}
    >
      <div style={{ textAlign: "center", marginTop: 24 }}>
        <ConfirmFailIcon />
        <Text
          size={36}
          lnHeight="36px"
          weight={700}
          color="#fb4e4e"
          mg="20px 0px 24px"
        >
          {value !== "future"
            ? t("transmission-failure")
            : t2("reservation-fail-title")}
        </Text>
        <FailText size={16} lnHeight="24px" weight={400}>
          {value !== "future"
            ? t2("send-the-survey-fail")
            : t2("reservation-fail-text")}
        </FailText>
        <FailText size={16} lnHeight="24px" weight={400} mg="0px 0px 24px">
          {t2("try-again")}
        </FailText>
        <ConfirmBtnStyled
          background="#E0E0E0"
          color="#FFFFFF"
          padding="11px 96px 13px"
          name={t("close-up")}
          border="none"
          bdr="6px"
          fontSize={16}
          lineHeight="16px"
          onClick={handleCancelNotication}
        />
      </div>
    </ConfirmModalStyled>
  );

  const renderNotificationModal = () => {
    if (isVisibleNotification.type === "success") {
      return <SuccessModal />;
    }
    return <FailureModal />;
  };

  const ConfirmModal = () => (
    <ConfirmContainer>
      <TextConfirm>
        {value === "future" ? t2("book-survey") : t2("submit-your-survey")}
        <br></br>
        {t("are-you-ok")}
      </TextConfirm>
      <ButtonContainer>
        <Button
          background="#E0E0E0"
          color="#FFFFFF"
          padding="11px 24px 13px"
          name={t1("cancel")}
          border="none"
          bdr="6px"
          fontSize={16}
          fontWeight={700}
          lineHeight="16px"
          onClick={handleCancelConfirmMessage}
        />
        <Button
          background="#2AC769"
          color="#FFFFFF"
          padding="11px 48px 13px"
          name={t("yes")}
          border="none"
          bdr="6px"
          fontSize={16}
          fontWeight={700}
          lineHeight="16px"
          disabled={sendQuestionnaireLoading}
          onClick={onSendQuestionnaireAPI}
        />
      </ButtonContainer>
    </ConfirmContainer>
  );

  const onSubmit = () => {
    setShowConfirm(true);
  };

  const handleCancelSendMessage = () => {
    handleCancelSendMessageModal();
    setIsDisabled(false);
    setValue("future");
  };

  const handleOnSelectStartDate = (date: Date) => {
    const start = moment(date).format(format);
    const now = handleGetTimeByTimeZone(format, currentTimeZone, new Date());
    setEndDate(moment(date).add(15, "d").toDate());

    if (start === now) {
      const hour =
        moment(
          handleGetTimeByTimeZone(
            "YYYY/MM/DD HH:mm",
            currentTimeZone,
            new Date()
          )
        ).hour() + 1;

      const timeOptions: any = handleTimeOptions(hour);

      setTimeValue(timeOptions);
      setStartDateTime(`${hour}:00`);
      setMinDate(date);
      setStartDateOfEndDatePicker(date);
    } else {
      setTimeValue(timeValueDefaults);
      setStartDateTime("00:00");
    }
  };

  const handleOnSelectEndDate = (date: Date) => {
    const end = moment(date).format(format);
    const start = moment(startDate).format(format);
    const [hour] = startDateTime.split(":");

    if (start === end) {
      const hourValue = Number(hour) + 1;
      setEndTimeOptions(handleTimeOptions(hourValue));
      setEndDateTime(`${hourValue}:00`);
    } else {
      setEndTimeOptions(timeValueDefaults);
      setEndDateTime("00:00");
    }
  };

  const renderSendMessageModal = () => (
    <SendMessageModalStyled
      type="form"
      title={t2("send-confirmation-label")}
      visible={isVisible}
      onCancel={handleCancelSendMessage}
    >
      {showConfirm && <ConfirmModal />}
      <form onSubmit={handleSubmit(onSubmit)}>
        <SectionContainer>
          <Label>{t("address")}</Label>
          <TagsContainer>
            <BadgeContainer>
              {isSendAll ? (
                <SendAllBadge>{t("all-registrants")}</SendAllBadge>
              ) : (
                <>
                  {selectedRows.map((user: any) => (
                    <div key={user.id}>
                      <p>{user.name}</p>
                      <CloseIcon
                        fill="#3B3B3B"
                        onClick={() => {
                          handleRemoveDestination(user.id);
                          handleDisabledBtn(user.id);
                        }}
                      />
                    </div>
                  ))}
                </>
              )}
            </BadgeContainer>
          </TagsContainer>
        </SectionContainer>
        <Label>{t2("question-content")}</Label>
        <div
          style={{
            fontWeight: 700,
            fontSize: 16,
            lineHeight: "22px",
            margin: "8px 0px 16px",
          }}
        >
          {record?.title}
        </div>
        {record?.questionnaire_questions.map((item: any, index: any) => (
          <Question key={index}>
            <div className="question">
              {t2("question")}
              {item.question_position}：
            </div>
            {item.question_content}
          </Question>
        ))}
        <Radio.Group onChange={onChange} value={value}>
          <Radio value="future">{t2("select-a-period")}</Radio>
        </Radio.Group>
        {value === "future" && (
          <WrapperSelectPeriod>
            <WrapperPeriod>
              <div
                style={{
                  minWidth: "fit-content",
                  marginTop: !screens.xs ? 10 : 0,
                }}
              >
                {t2("implementation-period")}：
              </div>
              <DateRangePicker
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                locale={currentLanguage}
                maxDate={false}
                minDate={minDate}
                type="for-modal"
                startDateTime={startDateTime}
                endDateTime={endDateTime}
                onChangeStartDateTime={handleOnChangeStartDateTime}
                onChangeEndDateTime={handleOnChangeEndDateTime}
                timeValue={timeValue}
                endTimeOptions={endTimeOptions}
                onSelectStartDate={handleOnSelectStartDate}
                onSelectEndDate={handleOnSelectEndDate}
                startDateOfEndDatePicker={startDateOfEndDatePicker}
              />
            </WrapperPeriod>
            <Button
              onClick={() => setShowConfirm(true)}
              type="submit"
              name={t2("save-duration-and-done")}
              background={"#2AC769"}
              color="#FFFFFF"
              border="none"
              fontSize={16}
              fontWeight={700}
              padding="7px 24px"
              style={
                screens.md
                  ? currentLanguage === "ja"
                    ? { marginLeft: 74 }
                    : { marginLeft: 160 }
                  : {}
              }
            />
          </WrapperSelectPeriod>
        )}

        <Line />
        <WrapperSendImmediately>
          <Radio.Group onChange={onChange} value={value}>
            <Radio value="immediately">{t2("take-survey-now")}</Radio>
          </Radio.Group>
          {value === "future" && (
            <Button
              background="#E0E0E0"
              color="#FFFFFF"
              name={t1("cancel")}
              border="none"
              bdr="6px"
              fontSize={16}
              fontWeight={700}
              lineHeight="16px"
              padding="11px 24px 13px"
              onClick={(e: any) => {
                e.preventDefault();
                handleCancelSendMessage();
              }}
            />
          )}
        </WrapperSendImmediately>
        {value === "immediately" && (
          <div style={{ marginLeft: 24 }}>
            <div>{t2("period-description")}</div>
            <div style={{ marginBottom: 16 }}>{t2("period-description-2")}</div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                onClick={() => setShowConfirm(true)}
                type="submit"
                name={t2("send-survey")}
                background={"#2AC769"}
                color="#FFFFFF"
                border="none"
                fontSize={16}
                fontWeight={700}
                padding="7px 24px"
                disabled={isDisabled}
                width={screens.xs ? "100%" : "auto"}
                icon={
                  sendQuestionnaireLoading && (
                    <LoaderIcon
                      width="20px"
                      height="20px"
                      style={{
                        marginTop: 2,
                        position: "absolute",
                        left: 12,
                        top: 8,
                      }}
                    />
                  )
                }
              />
              <Button
                background="#E0E0E0"
                color="#FFFFFF"
                name={t1("cancel")}
                border="none"
                bdr="6px"
                fontSize={16}
                fontWeight={700}
                lineHeight="16px"
                padding="11px 24px 13px"
                onClick={(e: any) => {
                  e.preventDefault();
                  handleCancelSendMessage();
                }}
              />
            </div>
          </div>
        )}
      </form>
    </SendMessageModalStyled>
  );

  return (
    <>
      {isVisibleNotification.status
        ? renderNotificationModal()
        : renderSendMessageModal()}
    </>
  );
};

export default ConfirmTransmissionModal;
